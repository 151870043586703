.success-modal {
  font-family: PingFang SC;
  align-items: center;
  display: flex;
  flex-direction: column;
  .success-info {
    margin-top: 36px;
    font-size: 24px;
    font-weight: 600;
    line-height: 33.6px;
    text-align: center;

    &-desc {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 25.53px;
      text-align: left;
      color: rgba(37, 41, 49, 1);
    }
  }
  .footer {
    margin-top: 41px;
    .btn {
      width: 340px;
      height: 56px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;

    }
  }
}
