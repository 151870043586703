.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 800px;
  box-sizing: border-box;
  padding-top: 80px;
  background: #FFFFFF;
  
  .title {
    width: 100%;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    color: #000000;
    text-align: center;
  }

  .subhead {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 16px;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #1966ff;
    box-sizing: border-box;
    margin-top: 42px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #1966ff;
    cursor: pointer;

    &:hover {
      background: #1966ff;
      color: #ffffff;
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 534px;
    box-sizing: border-box;
    padding-top: 53px;

    .title {
      width: 100%;
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      color: #000000;
      text-align: center;
    }

    .subhead {
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 10px;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #1966ff;
      box-sizing: border-box;
      margin-top: 28px;
      font-size: 12px;
      line-height: 13px;
      text-align: center;
      color: #1966ff;
      cursor: pointer;

      &:hover {
        background: #1966ff;
        color: #ffffff;
      }
    }
  }
}
