.wrap {
  padding-bottom: 40px;

  .contentImg {
    width: 1393px;
    margin-top: 20px;
    
    &-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

@media (max-width: 1636px) {

  .wrap .contentImg {
    width: 929px;
  }
}