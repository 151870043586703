.wrapper {
  margin-top: 80px;
  padding-top: 54px;
  padding-bottom: 20px;
  background: #F6FAFF;

  .main {
    width: 1030px;
    margin: 0 auto;
  }

  .title {
    position: relative;
    text-align: center;
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 18px;
  }

  .moreDetail {
    position: absolute;
    right: 0;
    bottom: 2px;

    .link {
      color: rgba(25, 102, 255, 1);
    }
  }

  .cardContainer {
    display: flex;
    justify-content: center;
  }
}
