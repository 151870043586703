.merchantRightCard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  .introduce {
    font-size: 14px;
    line-height: 20px;
    height: 60px;
    text-align: left;
    width: 100%;
    color: rgba(127, 127, 127, 1);
    display: -webkit-box; 
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
  }

  .name {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, .85);
    width: 202px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .cardContainer {
    width: 242px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 12px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  
}
