.appointmentSuccess {

  padding: 24px 24px 0 24px;
  text-align:  center;
  background-image: url(./bg.png);
  background-size: contain;
  background-repeat: no-repeat;

  .title {
    padding-bottom: 24px;
    color: #252931;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    padding: 0 16px;
    background: linear-gradient(180deg, #EDF2FF 0%, #F0F4FF 100%);
    border-radius: 6px;
  }

  .text {
    position: relative;
    padding: 16px 0 16px 16px;
    font-size: 14px;
    color: #252931;
    line-height: 16px;
    text-align: left;

    &:nth-child(1) {
      border-bottom: 1px dashed #C2DDFF;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 20px;
      content: ' ';
      background-color: #252931;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  .btn {
    margin-top: 24px;
    height: 36px;
    border-radius: 18px;
  }
}
