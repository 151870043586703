@import "~statics/styles/mixins.scss";

.wrapper {
  @include FlexCenter();
  width: 100%;
  box-sizing: border-box;
  padding: 60px 0 108px 0;
  background: #ffffff;

  .content {
    width: 1480px;

    .title {
      @include FlexCenter();
      width: 100%;

      &::before {
        content: "";
        display: inline-block;
        width: 300px;
      }

      &-before-hide {
        &::before {
          display: none
        }
      }

      h2 {
        @include FlexCenter();
        flex: 1;
        font-weight: 500;
        font-size: 36px;
        line-height: 50px;
        text-align: center;
        color: #000000;
      }

      a {
        width: 300px;
        display: inline-block;
        font-size: 20px;
        line-height: 24px;
        color: #1966ff;
        text-align: right;
        box-sizing: border-box;
        padding-right: 66px;

        &::after {
          content: "";
          display: inline-block;
          width: 8.7px;
          height: 14.5px;
          background: url("~pages/home/img/rights-interests/arrow.svg")
            no-repeat;
          background-size: 8.7px 14.5px;
          margin-left: 16px;
        }
      }
    }

    .card-box {
      @include FlexCenter();
      justify-content: flex-start;
      margin-top: 41px;
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    @include FlexCenter();
    width: 100%;
    box-sizing: border-box;
    padding: 53px 144px 64px 144px;
    background: #ffffff;

    .content {
      width: 986px;

      .title {
        @include FlexCenter();
        width: 100%;

        &::before {
          content: "";
          display: inline-block;
          width: 300px;
        }

        &-before-hide {
          &::before {
            display: none
          }
        }

        h2 {
          @include FlexCenter();
          flex: 1;
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;
          text-align: center;
          color: #000000;
        }

        a {
          width: 300px;
          display: inline-block;
          font-size: 12px;
          line-height: 15px;
          color: #1966ff;
          text-align: right;
          box-sizing: border-box;
          padding-right: 66px;

          &::after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 10px;
            background: url("~pages/home/img/rights-interests/arrow.svg")
              no-repeat;
            background-size: 6px 10px;
            margin-left: 10px;
          }
        }
      }

      .card-box {
        margin-top: 41px;
      }
    }
  }
}
