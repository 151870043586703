.InvestmentCondition {
  margin: 64px 160px 99px 46px;

  .header {

    .tip {
      font-size: 20px;
      margin-top: 16px;
      margin-bottom: 24px;
      margin-left: 38px;
    }

    img {
      height: 36px;
      width: 36px;
      vertical-align: middle;
    }

    .title {
      display: inline-block;
      vertical-align: middle;
      font-size: 28px;
      color: rgba(0, 0, 0);
    }
  }

  .conditionList {
    font-size: 20px;
    margin-top: 16px;
    margin-left: 38px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    .item {
      line-height: 32px;
    }

  }
}

@media (max-width: 1636px) {
  .InvestmentCondition {

    margin: 40px 27px 40px 26px;

    .header {

      .tip {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 30px;
        margin-bottom: 20px;
      }

      img {
        height: 24px;
        width: 24px;
      }

      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .conditionList {
      font-size: 14px;
      margin-top: 10px;
      margin-left: 30px;

      img {
        width: 12px;
        height: 12px;
        margin-right: 2px;
      }

      .item {
        line-height: 28px;
      }

    }
  }
}