@import "~statics/styles/mixins.scss";

.wrapper {
  //height: 620px;
  position: relative;
  overflow: hidden;

  :global {
    #oauth-login {
      margin-top: -20px;
    }
  }

  &-hide-dots {
    & :global {
      .slick-dots {
        display: none !important;
      }
    }
  }
}

@keyframes moveAnimation {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

$width: 180px;

.wrapper.v2 {
  .carousel {
    &-item {
      height: 566px;
    }
    &-img {
      width: 1644px;
      height: 100%;
    }
    &-nav {
      bottom: 80px;
    }
  }
}
.carousel {
  position: relative;
  &-item {
    width: 100%;
    // height: 800px;
    height: 749px;
    position: relative;
    overflow: hidden;

    &-cursor {
      cursor: pointer;
    }

    &-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2412px;
      object-fit: contain;
    }
  }

  &-nav {
    position: absolute;
    // top: 200px;
    left: calc(100vw / 2 - 720px);
    width: 900px;
    bottom: 123px;

    .content {
      display: flex;

      width: 900px;
      height: 44px;
      border-radius: 4px;
      overflow: hidden;

      &-item {
        position: relative;
        height: 40px;
        opacity: 0.7;
        backdrop-filter: blur(8px);
        background-color: #fff;

        &-active {
          opacity: 1;
        }

        .nav {
          @include FlexCenter();
          justify-content: flex-start;

          width: $width;
          height: 40px;
          background-color: #fff;
          box-sizing: border-box;
          padding-left: 12px;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 2px;
          cursor: pointer;
          color: #000000;

          &-icon {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            border-radius: 2px;
          }
        }

        .process {
          position: relative;
          width: $width;
          height: 4px;
          background: rgba(196, 196, 196, 0.6);
          overflow: hidden;

          &-active {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
            width: $width;
            height: 4px;
            background: #1966ff;
            animation-name: moveAnimation;
            animation-fill-mode: forwards;
            animation-timing-function: linear;
          }
        }
      }
    }
  }

  & :global {
    .slick-dots {
      position: relative;
      top: -150px;
    }
  }
}

.login-wrap {
  position: absolute;
  top: 129px;
  right: calc((100vw - 1360px) / 2);
}

@media (max-width: 1636px) {
  .wrapper {
    position: relative;
    :global {
      #oauth-login {
        margin-top: -20px;
      }
    }
  }

  $width: 120px;
  $height: 26px;

  .carousel {
    &-item {
      width: 100%;
      height: 566px;
      position: relative;
      overflow: hidden;

      &-cursor {
        cursor: pointer;
      }

      &-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1644px;
        object-fit: contain;
        height: 100%;
      }
    }

    &-nav {
      position: absolute;
      left: calc(50% - 493px);

      width: auto;
      bottom: 79px;


      &-hot {
        bottom: 50px;
      }

      .content {
        display: flex;
        width: auto;
        height: $height;
        border-radius: 4px;
        overflow: hidden;

        &-item {
          position: relative;
          height: calc($height - 2px);
          opacity: 0.7;

          &-active {
            opacity: 1;
          }

          .nav {
            @include FlexCenter();
            justify-content: flex-start;

            width: $width;
            height: calc($height - 2px);
            background-color: #fff;
            box-sizing: border-box;
            padding-left: 4px;
            cursor: pointer;

            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1.5px;

            color: #000000;

            &-icon {
              width: 21px;
              height: 21px;
              margin-right: 5px;
              border-radius: 2px;
            }
          }

          .process {
            position: relative;
            width: $width;
            height: 2px;
            background: rgba(196, 196, 196, 0.6);
            overflow: hidden;

            &-active {
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 2;
              width: $width;
              height: 2px;
              background: #1966ff;
              animation-name: moveAnimation;
              animation-fill-mode: forwards;
              animation-timing-function: linear;
            }
          }
        }
      }
    }

    & :global {
      .slick-dots {
        position: relative;
        top: -100px;
      }
    }
  }

  .login-wrap {
    position: absolute;
    top: 44px;
    right: calc((100% - 1032px) / 2);
    transform: scale(0.87);
  }
}
