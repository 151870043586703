@import "../../statics/styles/mixins.scss";

$height: 60px;
$width: 290px;

.settleButton {
  @include FlexCenter();
  position: relative;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #ffffff;
  width: $width;
  height: $height;
  background: #1966ff;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    background: #4784FF;
  }

  &-fixed {
    position: fixed;
    bottom: 80px;
    left: 50%;
    width: $width;
    height: $height;
    transform: translateX(-50%);
    z-index: 500;
    filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));
  }

  &-sticky {
    pointer-events: none;
    position: sticky;
    bottom: 0;
    left: 50%;
    width: $width;
    transform: translateX(-50%);
    padding-bottom: 40px;
    z-index: 500;
    filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));

    > div {
      pointer-events: all;
      cursor: pointer;
    }
  }

  &-img {
    position: absolute;
    width: 100%;
    height: $height;
    left: 100px;
    top: 0;
    animation: straightLine 2.5s infinite ease;
    -webkit-animation: straightLine 2.5s infinite ease;
  }
}

.settleButtonRetail {
  position: relative;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  width: $width;
  height: $height;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(25, 102, 255, 1);
  color: rgba(25, 102, 255, 1);
  background: #f0f6ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes straightLine {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@-webkit-keyframes straightLine {
  from {
    left: 0;
  }

  to {
    left: 190px;
  }
}

@media (max-width: 1636px) {
  $height: 40px;
  $width: 190px;

  .settleButton {
    @include FlexCenter();
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 13px;
    color: #ffffff;
    width: $width;
    height: $height;
    background: #1966ff;
    border-radius: 4px;
    overflow: hidden;

    &-fixed {
      position: fixed;
      bottom: 0;
      left: 50%;
      width: $width;
      height: $height;
      transform: translateX(-50%);
      z-index: 500;
      filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));
    }

    &-sticky {
      position: sticky;
      bottom: 0;
      left: 50%;
      width: $width;
      height: $height;
      transform: translateX(-50%);
      z-index: 500;
      filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));
    }

    &-img {
      position: absolute;
      width: 100%;
      height: $height;
      left: 100px;
      top: 0;
      animation: straightLine 2.5s infinite ease;
      -webkit-animation: straightLine 2.5s infinite ease;
    }
  }
  .settleButtonRetail {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 13px;
    color: rgba(25, 102, 255, 1);
    width: $width;
    height: $height;
    background: #f0f6ff;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(25, 102, 255, 1);
  }
}


.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2 * $width;
  gap: 0 16px;
}