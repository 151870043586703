.agreement {
  font-size: 12px;
  color: #AAACAF;
  text-align: center;

  .link {
    padding: 0 3px;
    color: #55585c;
  }
}
