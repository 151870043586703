.container {
  padding: 20px 0;
  margin: 0 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #252931;

    .left {
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }

    .des {
      color: #1966FF;
    }

    .right {

    }
  }

  .body {
    padding-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #252931;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #D8E7FF;;
  }
}
