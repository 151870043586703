.wrapper {
  position: relative;

  .topBanner {
    width: 100%;
    height: 380px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    
    .mainBg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

