.selectShopType {
  //margin-bottom: 34px;
  margin-top: 24px;

  .formItemWrapper {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 14px;
    }
  }

  .formItem {
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      width: 102px;
      margin-right: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #55585c;

      img {
        cursor: pointer;
        margin-left: 3px;
      }
    }

    .radio {
      width: 96px;
    }

    :global {

      .auxo-checkbox-wrapper:not(.sp-null) .auxo-checkbox-checked:not(.auxo-checkbox-indeterminate) .auxo-checkbox-inner::before {
        top: 30%;
        content: "✓" !important;
        color: white;
        background-image: unset;
      }
    }
  }
}

.newIcon {
  background: url('../../img/new-icon.svg') no-repeat center;
  background-size: cover;
  display: inline-block;
  width: 21px;
  height: 16px;
  vertical-align: sub;
  margin-left: 4px;
  transform: translate(0px, -2px);
}