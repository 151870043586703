.categoryCard {
  display: flex;
  flex-wrap: wrap;

  .cardContainer {
    margin-bottom: 12px;
    padding: 12px;
    width: 184px;
    height: 134px;
    background-color: #fff;
    border: 1px solid #E0EDFF;
    border-radius: 12px;
  }

  .cardContainer:not(:nth-child(4n)) {
    margin-right: 12px;
  }

  .name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, .85);
    width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 8px;
  }

  .link {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}
