
@import "~statics/styles/mixins.scss";

.hot {
  position: sticky;
  transition: all 0.3s;
  top: 88px;
  width: 260px;
  overflow: hidden;
  height: 100%;
  background-color: #fff;
  border-left: 0.5px solid #EDEEF0;
  padding-left: 24px;
  padding-top: 18px;

  &-icon {
    background: url('../../assets/hot.svg') no-repeat center;
    background-size: cover;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: 2px;
    left: -4px;
  }

  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .listBox {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-item {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 10px;

    &:first-child {
      margin-top: 0;
    }

    .link {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #252931;
      margin-bottom: 8px;
      @include FlexStart;
      cursor: pointer;

      &-title {
        @include MultiLineEllipsis(2);
        float: left;

        &::before {
          content: "";
          display: inline-block;
          background: url('../../assets/q.svg') no-repeat center;
          background-size: 16px;
          width: 16px;
          height: 16px;
          margin-right: 4px;
          position: relative;
          top: 2px;
        }
      }

      &::after {
        content: "";
        display: inline;
        background: url('../../assets/arrow-w.svg') no-repeat center;
        background-size: 14px;
        width: 14px;
        height: 14px;
        margin-right: 4px;
        flex-shrink: 0;
        position: relative;
        top: 1px;
      }

      &:hover {
        color: #4784FF;

        &::after {
          background: url('../../assets/arrow.svg') no-repeat center;
        }
      }

    }

    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #898B8F;
      @include MultiLineEllipsis(3);
    }
  }
}

@media (max-width: 1636px) {
  .hot {
    top: 56px;
  }
}