.wrapper {
  min-height: calc(100vh - 236px);
  background-color: #f3f4f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 48px;
}

.wrapperV2 {
  padding: 0;
  min-height: 546px;
}

.flowBg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: inset 0px 4px 20px rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  margin-top: 23px;
}

.container {
  width: 1548px;
  padding: 32px;
  background-color: #fff;
  border-radius: 4px;
  min-height: 200px;
  background: url('./img/bg.jpeg') no-repeat center;
  background-size: cover;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
}

.desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.process {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &-item {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    flex: 1;
    padding: 0 27px;

    &::after {
      display: block;
      content: "";
      border-top: 2px solid #eef5ff;
      width: calc(100% - 102px);
      position: absolute;
      left: 50%;
      top: 50px;
      transform: translateX(51px);
    }

    &:last-of-type {
      &::after,
      .arrow {
        display: none;
      }
    }

    &-img {
      width: 102px;
      height: 102px;
      border-radius: 50%;
    }

    &-title {
      margin-top: 16px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &-time {
      display: inline-block;
      margin-top: 16px;
      background-color: #f9f9fa;
      border-radius: 10px;
      padding: 2px 8px;
      color: #85878c;
    }

    &-desc {
      margin-top: 2px;
      color: #898b8f;

      &-item {
        margin-top: 8px;
      }
    }
  }

  .jump-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;

    .jump {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #1966ff;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 4px;
    }
  }

  .arrow {
    background-color: #fff;
    width: 16px;
    height: 16px;
    position: absolute;
    right: -8px;
    top: 43px;
    z-index: 2;
  }
}

.tip {
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  color: #898b8f;

  .tipLink {
    background-color: transparent;
    color: #1966ff;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;
  }
}

.enter-settle-btn {
  height: 48px;
  width: 151px;
  background-color: #1966ff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;

  &:hover {
    cursor: pointer;
  }

  &-wrap {
    margin-top: 64px;
    display: flex;
    justify-content: center;
  }
}

.hoverNav {
  transform: translateY(-176px);
}

.hidden_padding {
  padding: 0;
}

.settle-video-module {
  height: unset;
}

@media (max-width: 1636px) {
  .container {
    padding: 32px;
    background-color: #fff;
    border-radius: 4px;
    min-height: 200px;
    width: 1160px;
  }

  .enter-settle-btn-wrap {
    position: relative;
    top: -50px;
    margin-top: 0;
  }


  .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .desc {
    font-size: 16px;
    line-height: 21px;
  }

}
