.bg {
  position: relative;
  display: flex;
  justify-content: center;
  // height: 850px;

  & > div {
    width: 100%;
  }

  & :global {
    .slick-dots {
      display: none !important;
    }
  }
}

.carousel-item {
  width: 100%;
  height: 850px;
  position: relative;
  overflow: hidden;
  background: rgb(235, 242, 255);

  &-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  &-content{
    position: absolute;
    top: 418px;
    left: 218px;
    display: flex;
    width: 762px;
    padding: 70px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.75) 61.21%, rgba(255, 255, 255, 0.00) 96.93%);
    // background: linear-gradient(90deg, rgba(255, 255, 255, 0.30) 61.21%, rgba(255, 255, 255, 0.00) 96.93%);
    // border-image:linear-gradient(to right, #FFFFFF, rgba(255, 255, 255, 0.00) 96.93%);
    // border-image-slice: 1;
    border-radius: 16px;
    .title{
      color: rgba(0, 0, 0, 0.85);
      font-family: "PingFang SC";
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
    }
    .content{
      color: var(--color-text-icon-1, #5E6066);
      font-family: "PingFang SC";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
    }
  }
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 84px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #85878a;
    // margin-top: 8px;
  }

  .tab {
    display: flex;
    align-items: flex-end;
    height: 80px;
    // color: red;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 320*4px;
    border-radius: 8px;
    background: #ffffff;
    margin-top: 40px;
    &-item:hover{
      color: #fff;
      box-shadow: 0px 6px 32px rgba(53, 76, 166, 0.08);
      border-radius: 8px;
      background: linear-gradient(99.13deg, #80aaff 11.62%, #1966ff 86.65%);
      
      &-title{
        color: #fff;
      }
    }
    &-item {
      display: flex;
      width: 312px;
      height: 72px;
      margin-left: 6px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      background-color: #fff;
      position: relative;
      // transition: all 0.5s;
      cursor: pointer;
      // transition: all .3s;
      &-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      &-logo{
        width: 40px;
        height: 40px;      
      }

      &-title {
        margin-left: 12px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
      }

      &-subtitle {
        margin-left: 46px;
        font-size: 20px;
        line-height: 28px;
        color: #7f7f7f;

      }

      &-active {
        border-bottom: 4px #1966ff solid;
        height: 144px;
      }

      &-icon-one,
      &-icon-two {
        width: 20px;
        height: 20px;
        position: absolute;
      }

      &-icon-one {
        background-image: url(../../img/block3/active-icon-1.png);
        background-size: 100% 100%;
        right: 4px;
        top: -10px;
      }

      &-icon-two {
        background: rgba(222, 222, 222, 0.2);
        border: 0.5px solid rgba(13, 13, 13, 0.04);
        backdrop-filter: blur(2.3px);
        border-radius: 50%;
        right: -7px;
        top: -5px;
      }
    }
    &-activity{
      color: #fff;
      box-shadow: 0px 6px 32px rgba(53, 76, 166, 0.08);
      border-radius: 8px;
      background: linear-gradient(99.13deg, #80aaff 11.62%, #1966ff 86.65%);
      &-title{
        color: #fff;
      }
    }
    &-item:first-child{
      border-radius: 8px;
    }
    &-item:last-child{
      border-radius: 8px;
    }
    
  }
}

@media (max-width: 1636px) {
  .bg {
    position: relative;
    display: flex;
    justify-content: center;
    height: 850*0.75px;

    & > div {
      width: 100%;
    }
  
    & :global {
      .slick-dots {
        display: none !important;
      }
    }
  }

  .carousel-item {
    width: 100%;
    height: 850*0.75px;
    position: relative;
    overflow: hidden;
    background: rgb(235, 242, 255);
  
    &-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      > img {
        object-fit: cover;
      }
    }
    &-content{
      position: absolute;
      top: 418*0.75px;
      left: 12%;
      display: flex;
      width: 762*0.75px;
      padding: 70*0.75px;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
      // border: 1px solid;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.75) 61.21%, rgba(255, 255, 255, 0.00) 96.93%);
      // background: linear-gradient(90deg, rgba(255, 255, 255, 0.30) 61.21%, rgba(255, 255, 255, 0.00) 96.93%);
      // border-image:linear-gradient(to right, #FFFFFF, rgba(255, 255, 255, 0.00) 96.93%);
      // border-image-slice: 1;
      border-radius: 16px;
      .title{
        color: rgba(0, 0, 0, 0.85);
        font-family: "PingFang SC";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
      .content{
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
      }
    }
  }
  
  .container {
    position: absolute;
    // top: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #000000;
      margin-top: 80*0.75px;
      margin-bottom: 16*0.75px;
    }
    .subtitle {
      color: #85878a;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      // margin-top: 8px;
    }

    .tab {
      display: flex;
      align-items: flex-end;
      height: 80*0.75px;
      // color: red;
      display: flex;
      flex-direction: row;
      width: 320*3px;
      border-radius: 8px;
      background: #ffffff;
      margin-top: 40*0.75px;
      align-items: center;
      &-item:hover{
        color: #fff;
        box-shadow: 0px 6px 32px rgba(53, 76, 166, 0.08);
        border-radius: 8px;
        background: linear-gradient(99.13deg, #80aaff 11.62%, #1966ff 86.65%);
        &-title{
          color: #fff;
        }
      }
      &-item {
        display: flex;
        width: 320*0.75-6px;
        height: 80*0.75-8px;
        margin-left: 5px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        background-color: #fff;
        position: relative;
        // transition: all 3s;
        cursor: pointer;
        &-header{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        &-logo{
          width: 30px;
          height: 30px;      
        }
  
        &-title {
          margin-left: 12px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 32*0.75px; /* 133.333% */
        }
  
        &-subtitle {
          color: #85878a;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;
        }

      }
      &-activity{
        color: #fff;
        box-shadow: 0px 6px 32px rgba(53, 76, 166, 0.08);
        border-radius: 8px;
        background: linear-gradient(99.13deg, #80aaff 11.62%, #1966ff 86.65%);
        &-title{
          color: #fff;
        }
      }
      &-item:first-child{
        border-radius: 8px;
      }
      &-item:last-child{
        border-radius: 8px;
      }
      
    }
  }
}
