// @import "../../../../statics/styles/mixins.scss";

$height: 60px;
$width: 290px;

// .footerSettleActionWrap {
//   display: flex;
//   gap:16px;
// }

// .footerSettleAction {
//   @include FlexCenter();
//   flex-shrink: 0;
//   position: relative;
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 20px;
//   color: #ffffff;
//   width: $width;
//   height: $height;
//   background: #1966ff;
//   border-radius: 4px;
//   overflow: hidden;
//   cursor: pointer;
//   transition: all .3s;
//   &:hover {
//     background: #4784FF;
//   }

//   &-fixed {
//     position: fixed;
//     bottom: 80px;
//     left: 50%;
//     width: $width;
//     height: $height;
//     transform: translateX(-50%);
//     z-index: 500;
//     filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));
//   }

//   &-sticky {
//     pointer-events: none;
//     position: sticky;
//     bottom: 0;
//     left: 50%;
//     width: $width;
//     transform: translateX(-50%);
//     padding-bottom: 40px;
//     z-index: 500;
//     filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));

//     > div {
//       pointer-events: all;
//       cursor: pointer;
//     }
//   }

//   &-img {
//     position: absolute;
//     width: 100%;
//     height: $height;
//     left: 100px;
//     top: 0;
//     animation: straightLine 2.5s infinite ease;
//     -webkit-animation: straightLine 2.5s infinite ease;
//   }
// }

// @keyframes straightLine {
//   from {
//     transform: translateX(-100%);
//   }

//   to {
//     transform: translateX(100%);
//   }
// }

// @-webkit-keyframes straightLine {
//   from {
//     left: 0;
//   }

//   to {
//     left: 190px;
//   }
// }

// @media (max-width: 1636px) {
//   $height: 40px;
//   $width: 190px;

//   .footerSettleAction {
//     @include FlexCenter();
//     position: relative;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 13px;
//     color: #ffffff;
//     width: $width;
//     height: $height;
//     background: #1966ff;
//     border-radius: 4px;
//     overflow: hidden;

//     &-fixed {
//       position: fixed;
//       bottom: 0;
//       left: 50%;
//       width: $width;
//       height: $height;
//       transform: translateX(-50%);
//       z-index: 500;
//       filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));
//     }

//     &-sticky {
//       position: sticky;
//       bottom: 0;
//       left: 50%;
//       width: $width;
//       height: $height;
//       transform: translateX(-50%);
//       z-index: 500;
//       filter: drop-shadow(0px 20px 20px rgba(45, 104, 246, 0.25));
//     }

//     &-img {
//       position: absolute;
//       width: 100%;
//       height: $height;
//       left: 100px;
//       top: 0;
//       animation: straightLine 2.5s infinite ease;
//       -webkit-animation: straightLine 2.5s infinite ease;
//     }
//   }
// }

.footerSettleAction {
  position: sticky;
  bottom: 0;
  inset: 0;
  z-index: 500;
  background: #fff;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);

  @keyframes straightLine {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  .button {
    width: $width;
    height: $height;
    padding: 4px 0;
    position: relative;
    background-color: #1966ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    font-weight: 500px;
    font-size: 20px;
    cursor: pointer;
  }

  .buttonInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .desc {
    font-size: 12px;
  }

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 100px;
    top: 0;
    animation: straightLine 2.5s infinite ease;
  }
}

// @media (max-width: 1636px) {
//   .footerSettleAction {
//     // $height: 40px;
//     // $width: 190px;

//     .button {
//       width: $width;
//       height: $height;
//       font-size: 16px;
//       padding: 4px 0;
//     }
//     .desc {
//       font-size: 12px;
//     }
//   }
  
// }
