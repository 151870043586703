.login {

    &-box {
        width: 352px;
        height: 480px;

        :global {

            .account-center-input-row {
                position: relative;
            }

            .account-center-code-captcha {
                padding-top: 9px;
                padding-right: 4px;

                height: 40px !important;
                position: absolute !important;
                top: -3px !important;
                right: 3px !important;
            }

            #oauth-login {
                margin-top: -20px;
            }

            .account-center-action-button {
                &:hover {
                    transition: all .3s;
                    background-color: #4784FF !important;
                }
            }

            .switch-type-switch {
                display: none !important;
            }

            a[href="/login"] {
                background-color: transparent;
                color: #1966ff;
                cursor: pointer;
                outline: none;
                text-decoration: none;
                transition: color .3s;
            }

            .account-center-header-common {
                display: flex !important;
                justify-content: center;
            }

            .account-center-sdk-form {
                margin-top: 24px !important;
            }
        }
    }
}

@keyframes fadeIn {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
};

@keyframes fadeOut {

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

.login-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    display: flex;
    background-color: #fff;
    border-radius: 4px;

    :global {
        #oauth-login {
            margin-top: -28px;
        }
    }

    &-right {
        width: 401px;
        height: 534px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &-show {
        position: fixed;
        z-index: 1000;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        animation: fadeIn .3s ease-in-out 0s 1 alternate forwards;
        background: rgba(0, 0, 0, .2);
        backdrop-filter: blur(4px);
    }

    &-loginWap {
        padding: 24px;
        position: relative;
        background: #fff;
        width: 400px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 4px;
    }

    &-close {
        position: absolute;
        right: 26px;
        top: 19px;
        width: 16px;
        height: 16px;
        z-index: 1000;
        background: url("https://lf3-cm.ecombdstatic.com/obj/ecom-ecop/170296905886e2ef378f997a09a158e6a2640556c5b5490248.svg") no-repeat;
        background-size: cover;
        cursor: pointer;
    }
    &-back {
        display: flex;
        align-items: center;
        position: absolute;
        left: 26px;
        top: 19px;
        z-index: 1000;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #898B8F;
        img {
            width: 12px;
            height: 12px;
            margin-right: 3px;
        }
    }
}

  .login-popup-sub-title {
    height: 480px !important;

    :global {

      .ecomLoginBox {
        height: 480px;
      }

      .account-center-header {
        height: 60px;
      }

      .account-center-header-sub-title {
        top: 40px !important;
        height: 30px !important;
        line-height: 28px !important;
        background-color: rgba(25, 102, 255, .1);
        background-image: url('../../../statics/img/login/sub-title-icon.svg');
        background-repeat: no-repeat;
        color: #1966ff !important;
        border-radius: 4px;
        margin-bottom: 8px;
        font-size: 14px !important;
        padding-left: 14px;
      }

    }

  }

  .login-panel {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      background: #fff;
      box-sizing: border-box;
      height: 100%;
      &-title {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          text-align: center;
          margin-top: 40px;
          color: #252931;
      }
      &-sub-title {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #898B8F;
          margin-top: 16px;
          &:before {
              content: " ";
              width: 60px;
              height: 1px;
              margin-right: 12px;
              background: #EEEFF0;
          }
          &:after {
              content: " ";
              width: 60px;
              height: 1px;
              margin-left: 12px;
              background: #EEEFF0;
          }
      }
      &-identity-list-v2 {
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          margin-top: 24px;
      }
      &-identity-item-v2 {
          width: 100%;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          background: linear-gradient(175.92deg, rgba(240, 248, 255, 0.6) 3.88%, rgba(239, 246, 255, 0.18) 97.18%);
          border-radius: 4px;
          padding: 8px 12px;
          &-content {
              flex: 1;
          }
          &-content :first-child {
              margin-top: 0;
          }
          &-left {
              margin-right: 12px;
          }
          img {
              width: 60px;
              height: 60px;
          }
          &-title {
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              color: #252931;
          }
          &-desc {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #898B8F;
              margin-top: 8px;
              text-align: left;
          }
          &-btn {
              &:hover {
                  z-index: 0 !important;
              }
              margin-left: 12px;
          }
      }
      &-identity-list {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 24px;
      }
      &-identity-item {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          width: 108px;
          height: 158px;
          background: linear-gradient(180deg, #F0F8FF 0%, rgba(239, 246, 255, 0.5) 100%);
          border-radius: 16px;
          padding: 16px 10px;
          cursor: pointer;
          img {
              width: 60px;
              height: 60px;
          }
          &-title {
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              margin-top: 10px;
              color: #252931;
          }
          &-desc {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #898B8F;
              margin-top: 8px;
              text-align: center;
          }
      }
      &-login {
          display: flex;
          align-items: center;
          margin-top: 40px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          &-button {
              margin-left: 8px;
          }
      }
  }

  .hide {
      display: none;
      visibility: hidden;
  }
  .show {
      visibility: visible;
  }