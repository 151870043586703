.agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #AAABAF;

  a {
    color: #55585C;
  }

  .unable_to_sign_in {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #55585C;
    margin-left: 8px;
    cursor: pointer;
    &:hover {
      color: #1966FF;
    }
  }
}
