@import "~statics/styles/mixins.scss";

@mixin A {
  color: #222526;
  font-size: 16px;

  &:active,
  &:hover {
    transition: all .3s;
    font-weight: 500;
  }
}

.header-box {
  @include FlexCenter();
  height: 88px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  transition: all .3s;

  &-center {
    justify-content: center;
  }

  .hot {
    width: 37px;
    height: 16px;
  }

  .fireHot {
    margin-right: 2px;
  }

  &-sticky {
    position: sticky;
    top: 0;
    z-index: 500;
  }

  &-scroll {
    box-shadow: 0 0 30px rgba(0, 0, 0, .06);
    background-color: rgba(255, 255, 255, 1);
  }

  .header {
    @include FlexCenter;
    justify-content: space-between;
    height: 100%;

    &-min-width {
      min-width: 1480px;
    }

    .nav-list {
      @include FlexCenter();
      justify-content: flex-start;
  
      .logo {
        width: 95px;
        height: 100%;
        margin-right: 24px;
      }
  
      .item {
        margin: 0 17px;
        position: relative;
  
        a {
          @include A();
        }
  
        &-active {

          a {
            @include A();
            font-weight: 500;
          }
  
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            background: url("../../statics/img/header/active-icon.svg") no-repeat;
            background-size: 16px 5px;
            display: inline-block;
            width: 16px;
            height: 5px;
          }
        }
      }
  
      .expand-black-arrow {
        width: 11px;
        height: 6px;
        margin-left: 4px;
      }
    }
  
    .right {
      @include FlexCenter();
      font-size: 16px;
  
      .mobile-phone-download {
        @include FlexCenter();
        color: #222526;
        transition: all .3s;
  
        &::before {
          content: "";
          display: inline-block;
          background: url("../../statics/img/header/phone.svg") no-repeat;
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
  
        &-wrapper {
          position: relative;
        }
      }
  
      .center {
        @include FlexCenter();
      }
  
      .btn {
        @include FlexCenter();
        width: 88px;
        height: 40px;
        border: 1px solid #1966ff;
        border-radius: 8px;
        margin-left: 16px;
        color: #1966ff;
        
        &:hover {
          border: 1px solid #4784FF;
          color: #4784FF;
        }
      }
  
      .settle {
        @include FlexCenter();
        @extend .btn;
        width: 104px;
        color: #fff;
        background: #1966ff;
        transition: all .3s;

        &:hover {
          background: #4784FF;
          color: #fff;
        }
      }

      .settleRetail {
        @include FlexCenter();
        @extend .btn;
        width: 104px;
        color: #1966ff;
        transition: all .3s;
        border: 1px solid #1966ff;
      }
    }
  }
}

.hotIcon {
  width: 21px;
  height: 16px;
  margin-left: 2.5px;
  position: relative;
  top: -2px;
}

:global {

  .new-header-sub-menu {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(0, 0, 0, .85);
  }
}

@media (max-width: 1636px) {

  :global {

    .new-header-sub-menu {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, .85);
    }
  }

  .header-box {
    @include FlexCenter();
    width: 100%;
    height: 56px;
    box-sizing: border-box;

    &-center {
      justify-content: center;
    }

    .hot {
      width: 37px;
      height: 16px;
    }

    &-sticky {
      position: sticky;
      top: 0;
      z-index: 500;
    }

    &-scroll {
      box-shadow: 0 0 30px rgba(0, 0, 0, .06);
    }

    .header {
      @include FlexCenter;
      justify-content: space-between;
      height: 100%;
      min-width: 1200px;
      
      .nav-list {
        @include FlexCenter();
        justify-content: flex-start;
  
        .logo {
          width: 84px;
          height: 100%;
          margin-right: 8px;
        }
  
        .item {
          margin: 0 8px;
          position: relative;
  
          a {
            @include A();
            font-size: 14px;
          }
  
          &-active {

            a {
              @include A();
              font-size: 14px;
              font-weight: 500;
            }
  
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              bottom: -5px;
              transform: translateX(-50%);
              background: url("../../statics/img/header/active-icon.svg")
                no-repeat;
              background-size: 16px 5px;
              display: inline-block;
              width: 16px;
              height: 5px;
            }
          }
        }
  
        .expand-black-arrow {
          width: 11px;
          height: 6px;
          margin-left: 3px;
        }
      }
  
      .right {
        @include FlexCenter();
        font-size: 14px;
        margin-left: 10px;
  
        .mobile-phone-download {
          @include FlexCenter();
          font-size: 14px;

          &::before {
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
  
        .center {
          @include FlexCenter();
        }
  
        .btn {
          @include FlexCenter();
          min-width: 88px;
          width: auto;
          padding: 0 3px;
          height: 30px;
          border: 1px solid #1966ff;
          font-size: 14px;
          border-radius: 8px;
          margin-left: 8px;
          color: #1966ff;
        }
  
        .settle {
          @include FlexCenter();
          @extend .btn;
          color: #fff;
          background: #1966ff;
          margin-left: 8px;
        }

        .settleRetail {
          @include FlexCenter();
          @extend .btn;
          margin-left: 8px;
        }
      }
    }
  }

  .hotIcon {
    transform: scale(.8);
    transform-origin: left;
    top: -1px;
  }
}