@import "~statics/styles/mixins.scss";

.bg {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 60px;
  background: linear-gradient(180deg, #f9fbff 0%, #e4eff9 100%);
  &.old {
    padding-top: 104px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content {
    width: 1480px;
    margin-top: 32px;
    background: url("../../img/block2/settle-bg.png") no-repeat;
    background-size: 1480px 100%;

    backdrop-filter: blur(20px);
    border-radius: 16px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  .enterContent {
    @extend .content;
    margin-top: 0;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: -80px;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(246, 251, 255, 0) 0%, #FDFEFF 70.43%) no-repeat center top/ 100% 100px;
  }

}

.process {
  margin-top: 32px !important;
}

.hide {
  display: none;
}

@media (max-width: 1636px) {
  .new-enter-flow {
    transform: scale(0.8);
    margin: 0;
  }

  .bg {
    min-height: 541px;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 77px;
    padding-bottom: 45px;
    background: linear-gradient(180deg, #f9fbff 0%, #e4eff9 100%);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .content {
      width: 986px;
      margin-top: 30px;
      background: url("../../img/block2/settle-bg.png") no-repeat;
      background-size: 986px 100%;
      border-radius: 16px;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }

    .enterContent {
      @extend .content;
      margin-top: 0;
    }
    &:after {
      content: ' ';
      position: absolute;
      top: -80px;
      width: 100%;
      height: 100px;
      background: linear-gradient(180deg, rgba(246, 251, 255, 0) 0%, #FDFEFF 70.43%) no-repeat center top/ 100% 100px;
    }
  }
}