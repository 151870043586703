.wrapper {
  height: 800px;
  background: url('https://lf1-fe.ecombdstatic.com/obj/ecom-cdn-default/sources/doudian/ffa-external/hot/hot-brand-banner3.png') center no-repeat;
  text-align: center;
  padding-top: 80px;
  > h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #fff;
  }
  > ul {
    margin: 102px auto 92px;
    //width: 1064px;
    > li {
      width: 306px;
      display: inline-block;
      vertical-align: middle;
      background: #ffffff;
      border-radius: 12px;
      height: 234px;
      padding: 0 32px;
      position: relative;
      .iconBox {
        position: absolute;
        top: -30px;
        left: 103px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #dce6ff center no-repeat;
        > img {
          width: 76px;
          height: 76px;
        }
      }
      > div:nth-child(2) {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #252931;
        margin: 90px 0 12px;
      }
      > div:last-child {
        font-size: 14px;
        line-height: 20px;
        color: #898b8f;
      }
      &:not(:first-child) {
        margin-left: 73px;
      }
    }
  }

  &-btn {
    border-radius: 48px;
    height: 48px;
    padding: 13px 112px;
    line-height: 20px;
    font-size: 16px;
    margin-top: 80px;
    color: #ffffff;
    background: #1966ff;
    border: 1px solid transparent;

    &:hover {
      background: #4784ff;
      color: #ffffff;
      border-color: transparent;
      z-index: 3;
    }
  }
}

.wrapper :global(.auxo-btn) {
  border-radius: 48px;
  height: 48px;
  padding: 13px 112px;
  font-size: 16px;
}
