@import "~statics/styles/mixins.scss";

.wrapper {
  position: relative;
  z-index: 0;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url('~statics/img/recruit/bg.png');

  .bg {
    position: absolute;
    left: calc(50vw - 722px);
    top: 177px;

    &-text {
      @include FlexCenter();
      justify-content: flex-start;
      font-weight: 600;
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 2px;
      color: #000000;
      margin-bottom: 40px;

      &-img {
        width: 140px;
        height: 33px;
        margin-right: 12px;
        position: relative;
        top: -2px;
      }
    }

    &-desc {
      font-weight: 600;
      font-size: 56px;
      line-height: 78px;
      color: #292b33;
    }
  }

  .loading-wrap {
    width: 1480px;
    margin-top: 310px;
    height: 765px;

    .card {
      display: flex;
      flex-direction: column;
      width: 1480px;
      height: 765px;
      background: #fff;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
      border-radius: 8px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 78px;
        box-sizing: border-box;
        padding: 22px 24px;
        border-bottom: 1px solid#EDEEF0;

        .title {
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #12141a;
        }

        .share {
          display: flex;
          align-items: center;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #1966ff;
          cursor: pointer;

          a {
            margin-left: 2px;
          }
        }
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;

        .menu {
          width: 209px;
          box-sizing: border-box;
          padding: 12px 30px;
          border-right: 1px solid#EDEEF0;
          height: 100%;

          &-item {
            width: 149px;
            height: 46px;
            overflow: hidden;
            box-sizing: border-box;
            padding: 12px 20px;
            margin-bottom: 12px;
            border-radius: 6px;
            transition: all 0.3s;
            cursor: pointer;
            font-family: "PingFang SC";
            
            &-active {
              background: rgba(25, 102, 255, 0.08);
              color: #1966ff;
            }
          }
        }

        .list-wrapper {
          width: 100%;
          // height: 100%;
          height: 686px;
          scroll-behavior: smooth;

          box-sizing: border-box;
          padding: 16px 0 12px 36px;
          margin-right: 24px;
          margin-bottom: 40px;
          overflow-y: scroll;
          overflow-x: hidden;
          position: relative;

          &-mask {
            position: absolute;
            bottom: 20px;
            right: 0;
            display: inline-block;
            width: 1265px;
            height: 23px;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 100%
            );
            opacity: 0.8;
          }
        }
      }
    }
  }

  .settle-btn {
    width: 100%;
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 26px;
    cursor: pointer;
  }
}

@media (max-width: 1636px) {
  .wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #ffffff;
    z-index: 0;

    .bg {
      position: absolute;
      left: calc(50vw - 492px);
      top: 119px;

      &-text {
        @include FlexCenter();
        justify-content: flex-start;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 2px;
        color: #000000;
        margin-bottom: 40px;

        &-img {
          width: 93.33px;
          height: 22px;
          margin-right: 8px;
          position: relative;
          top: -2px;
        }
      }

      &-desc {
        font-weight: 600;
        font-size: 36px;
        line-height: 50px;
        color: #292b33;
      }
    }

    .loading-wrap {
      width: 986px;
      height: 510px;
      margin-top: 200px;

      .card {
        display: flex;
        flex-direction: column;
        width: 986px;
        height: 510px;
        background: #fff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 78px;
          box-sizing: border-box;
          padding: 22px 24px;
          border-bottom: 1px solid#EDEEF0;

          .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #12141a;
          }

          .share {
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 12px;
            line-height: 13px;
            color: #1966ff;
            cursor: pointer;

            a {
              margin-left: 2px;
            }
          }
        }

        .content {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .menu {
            width: 139px;
            box-sizing: border-box;
            padding: 12px 20px;
            border-right: 1px solid#EDEEF0;
            height: 100%;

            &-item {
              width: 100px;
              height: 33px;
              overflow: hidden;
              box-sizing: border-box;
              padding: 8px 8px;
              margin-bottom: 12px;
              border-radius: 6px;
              transition: all 0.3s;
              cursor: pointer;
              font-size: 12px;
              line-height: 16px;

              &-active {
                background: rgba(25, 102, 255, 0.08);
                color: #1966ff;
              }
            }
          }

          .list-wrapper {
            width: 100%;
            height: 420px;
            scroll-behavior: smooth;

            box-sizing: border-box;
            padding: 16px 0 12px 24px;
            margin-right: 24px;
            margin-bottom: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
            position: relative;

            &-mask {
              position: absolute;
              bottom: 15px;
              right: 0;
              display: inline-block;
              width: 844px;
              height: 23px;
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 100%
              );
              opacity: 0.8;
            }
          }
        }
      }
    }

    .settle-btn {
      width: 100%;
      height: 108px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      cursor: pointer;
    }
  }
}

.toast {
  position: relative;
  top: 100px;
}
