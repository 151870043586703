.wrap {
  padding: 16px;
  zoom: 1.2;

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #252931;
  }

  .table {
    width: 100%;
    border-radius: 8px;
    font-size: 12px;
    line-height: 14px;
    color: #000;

    &-wrap {
      margin-top: 16px;
      border: 1px solid #EEEFF0;
      overflow: hidden;
      border-radius: 8px;
    }

    .head {
      background: #F8F9FA;

      th {
        font-weight: 500;
        height: 36px;
        width: 240px;
        padding-left: 16px;
      }

      th:nth-child(1){
        max-width: 145px;
        border-right: 1px solid #EEEFF0;
      }
    }

    .bodyItem {
      border-bottom: 1px solid #EEEFF0;
    }

    .tdKey {
      border-right: 1px solid #EEEFF0;
    }
    
    .key {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      // width: 100%;
      min-height: 34px;
      margin-left: 16px;
    }

    .val {
      padding: 10px 16px;
    }
  }
}