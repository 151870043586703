
.main-content-wrap {
  font-family: PingFang SC;
  padding: 0px 220px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(227, 230, 255, 0),
    rgba(247, 247, 255, 1) 10%,
    rgba(255, 255, 255, 1) 100%
  );
  font-family: PingFang SC;
  transform: translateY(-158px);
  margin-bottom: -158px;
  @media (max-width: 1636px) {
    padding: 0px 46px;
  }

  .main-content {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 1) 100%
    );
    border-radius: 16px;
    padding: 60px 80px;
  }
  .divider {
    height: 600px;
    border-color: rgba(230, 240, 255, 1);
    // margin-top: 128px;
    border-width: 2px;

  }
  .part-one {
    display: flex;
    .content-item {
      margin-bottom: 80px;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      line-height: 39.2px;
      text-align: left;
      color: #252931;
      img {
        width: 36px;
        margin-right: 8px;
      }
    }
    .desc {
      padding: 20px 0 0 20px;
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      color: #000;
      .sub-desc {
        color: rgba(86, 89, 96, 1);
        font-weight: 400;
        line-height: 36px;
      }
      .condition-item {
        font-weight: 400;
        line-height: 40px;
        display: flex;
        img {
          width: 20px;
          height: 20px;
          margin-top: 10px;
          margin-right: 4px;
        }
      }

    }

    .left {
      flex: 1;
      padding-right: 30px;
    }
    .right {
      flex: 1;
      padding-left: 30px;
    }


  }
}
