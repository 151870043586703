@import "~statics/styles/mixins.scss";

.wrap {
  flex: 1;
  border-radius: 16px;
  position: relative;
  margin-right: 24px;
  background-color: #fff;
  background: url('../../assets/enterStepBg.png') no-repeat center / cover;

  &:last-of-type {
    margin-right: 0;
  }

  .highlightTips {
    display: flex;
    align-items: center;
    padding: 7px 14px;
    color: #898B8F;
    background: linear-gradient(90deg, #F2F5F8 0%, #F7F8FA 101.4%);
    border-radius: 8px;
    margin: 12px 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &.highlight {
      color: #A35100;
      background: linear-gradient(90deg, #FBF4EE 0%, #FBF6F2 101.4%);
    }
    img {
      margin-right: 8px;
    }
  }
  .content {
    position: relative;
    height: 100%;

    .enterWrap {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 120px;
      background: url('https://lf3-cm.ecombdstatic.com/obj/ecom-ecop/171920864980eaca04935a7d3f8689e5e42da511e76f80dac9.png') no-repeat center / cover;
      //background: #63B1FF;
      padding-left: 130px;
      padding-right: 30px;
      box-sizing: border-box;
      color: #252931;
      &.active {
        background: url('../../assets/enterWrapBg.png') no-repeat center / cover;
        color: #fff;
        .btn {
          color: #1966FF;
        }
      }
      .logo {
        position: absolute;
        width: 140px;
        height: 140px;
        left: 0;
        bottom: 16px;
      }
      .left {
        margin-right: 24px;
      }
      .btn {
        flex-shrink: 0;
        padding: 9px 24px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 22px;
      }
    }
    &-enter {
      &::after {
        height: 100%;
      }
    }
  }

  &:nth-child(3n) {
    .content::after {
      display: none;
    }
  }
}

.enterFlowStepWrapper {
  padding: 40px 20px 40px 40px;
}

.enterFlowStep {
  @include FlexCenter;
  justify-content: space-between;
  padding-bottom: 26px;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 13.4px;
    top: 38px;
    width: 0;
    border: 1px dashed #1966FF;
    height: 50%;
    opacity: .4;
  }

  &:last-child{
    padding-bottom: 0;

    &::before {
      display: none
    }
  }

  &-left {
    width: 100%;

    &-title {
      @include FlexCenter;
      justify-content: flex-start;

      &-num {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #1966ff;
        margin-right: 11px;
        min-width: 28px;
      }

      &-text {
        @include MultiLineEllipsis(2);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        margin-right: 8px;
      }

      &-tag {
        background: #ffffff;
        padding: 5px 7px;
        font-size: 12px;
        line-height: 10px;
        text-align: center;
        color: #1966ff;
        background: rgba(25, 102, 255, 0.08);
        border-radius: 4px;
      }
    }

    &-desc {
      @include MultiLineEllipsis(2);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #898B8F;
      margin-top: 10px;
      margin-left: 40px;
      max-width: 400px;
      strong {
        color: #252931;
      }
    }
    &-stepBtn {
      margin-top: 12px;
      margin-left: 24px;
    }
  }

  &-right {
    @include FlexCenter;
    flex-direction: column;
    justify-content: flex-end;

    &-btn {
      @include FlexCenter;

      border: 1px solid #1966ff;
      border-radius: 3.83244px;
      box-sizing: border-box;
      padding: 3px 10px;

      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #1966ff;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        color: #4784FF;
        border: 1px solid #4784FF;
      }
    }
  }
}


.title {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px;
  white-space: nowrap;
}

.icon {
  width: 40px;
  height: 40px;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  @include MultiLineEllipsis(2);
}

.tag {
  margin-left: 8px !important;
  flex-shrink: 0;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexCenter {
  @include FlexCenter;
  // display: flex;
  // align-items: center;
}


@media (max-width: 1636px) {
  .title {
    font-size: 22px;
  }
}

:global {
  .auxo-badge-count {
    box-shadow: none;
    border-bottom-left-radius: 2px !important;
  }
}