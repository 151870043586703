.list-box {
  position: absolute;
  top: 34px;
  z-index: 999;
  background: #fff;
  width: 830px;
  max-height: 346px;
  overflow: auto;
  border: 1px solid #edeef0;
  box-shadow: 0px 4px 16px rgba(31, 37, 51, 0.08);
  .list-item:hover {
    color: #1966ff;
    background: #e3eefc;
    cursor: pointer;
  }
  .listTitle {
    margin: 0px 8px;
    padding: 5px 8px;
    border-top: 1px solid #ececec;
    font-size: 12px;
    line-height: 16px;
    color: #969696;
  }
}

// .list-item2 {
//   filter: opacity(60%);
//   &:hover {
//     color: #1966ff;
//     background: #e3eefc;
//   }
// }
