.larkGroupCardGuideWrapper {
  width: 232px;
  height: 272px;
  background: radial-gradient(36.57% 36.57% at 93.97% 0%, rgba(184, 174, 255, .2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(160.98deg, #eef4ff 1.45%, #fff 97.57%);
  box-shadow: 0 4px 40px rgba(0, 0, 0, .1);
  border-radius: 8px;
  padding: 16px;

  .cardGuidTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .cardGuidImg {
    width: 100%;
    height: 110px;
    margin-bottom: 8px;
    border-radius: 4px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .cardGuidDesc {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 22px;
  }

  .cardGuidButton {
    width: 200px;

    .button {
      width: 100%;
    }
  }
}


@media (max-width: 1636px) {

  .larkGroupCardGuideWrapper {
    width: 164px;
    height: 210px;
    box-shadow: 0 3px 26px rgba(0, 0, 0, .1);
    border-radius: 6px;
    padding: 10px;

    .cardGuidTitle {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    .cardGuidImg {
      height: 74px;
      margin-bottom: 8px;
      border-radius: 4px;
    }

    .cardGuidDesc {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 12px;
    }

    .cardGuidButton {
      width: 134px;

    }
  }
}