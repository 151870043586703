@mixin ItemListFontStyle {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 122px;
  box-sizing: border-box;
  cursor: pointer;
  align-items: flex-start;
  // margin-top: 26px;
  padding: 13px 40px 0 40px;

  &:nth-child(1){
    margin-top: 13px;
  }

  &:active {
    background: rgba(25, 102, 255, 0.1);
    .line {
      display: none;
    }
  }

  &:hover > .content_right > .content_right_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #1966ff;
  }

  .content_left {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #252931;
    margin-left: 6px;
  }

  .content_right {
    display: flex;
    flex-direction: column;

    .content_right_text {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #252931;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .content_right_date {
      @include ItemListFontStyle;
      font-size: 14px;
      line-height: 20px;
      color: #898b8f;
      margin-top: 16px;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    height: 1px;
    transform: translateX(-50%) scale(1, 0.5);
    background: #edeef0;
    left: 50%;
    bottom: 0;
  }

  .hidden_show_line {
    display: none;
  }

  .new_icon {
    position: absolute;
    top: 19px;
    transform: translateX(-100%);
    display: inline-block;
    color: #ffffff;

    .icon {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background: #f23755;
      border-radius: 2px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      padding-left: 1px;
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 94px;
    box-sizing: border-box;
    cursor: pointer;
    align-items: flex-start;
    padding-top: 13px;
    margin: 0;
    padding: 13px 26px 0 26px;
    
    &:nth-child(1) {
      margin-top: 13px;
    }

    &:active {
      background: rgba(25, 102, 255, 0.1);
      .line {
        display: none;
      }
    }

    &:hover > .content_right > .content_right_text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1966ff;
    }

    .content_left {
      font-weight: 500;
      color: #252931;
      font-size: 14px;
      line-height: 20px;
    }

    .content_right {
      display: flex;
      flex-direction: column;

      .content_right_text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #252931;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .content_right_date {
        @include ItemListFontStyle;
        font-size: 14px;
        line-height: 20px;
        color: #898b8f;
        margin-top: 10px;
      }
    }

    .line {
      position: absolute;
      width: 100%;
      height: 1px;
      transform: translateX(-50%) scale(1, 0.5);
      background: #edeef0;
      left: 50%;
      bottom: 0;
    }

    .hidden_show_line {
      display: none;
    }

    .new_icon {
      position: absolute;
      top: 15px;
      transform: translateX(-100%);
      display: inline-block;
      color: #ffffff;

      .icon {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        background: #f23755;
        border-radius: 2px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding-left: 1px;
      }
    }
  }
}
