@import "~statics/styles/mixins.scss";
$tagWidth: 183px;
$tagHeight: 56px;

.qualiContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .question-icon-new {
    width: 16px;
    height: 16px;
  }

  .selectShopType {
    display: flex;
    flex-direction: column;
    padding: 40px 60px 0 178px;
    width: 100%;

    :global {
      .ant-radio-button-wrapper {
        width: 128px;
        height: 40px;
        line-height: 40px;
      }
    }

    .searchBtn {
      width: 180px;
      height: 44px;
      margin-bottom: 40px;
      margin-top: 16px;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }

    .formItem {
      display: flex;
      align-items: center;

      &-wrapper {
        margin-bottom: 24px;
      }

      & :global {
        .ant-radio-button-wrapper {
          width: $tagWidth !important;
          height: $tagHeight !important;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 8px;
          margin-right: 24px;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #565960;
          border: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          background: #eff4ff;
          border: 1px solid #1966ff;
          border-radius: 8px;
          color: #1966ff;
          box-sizing: border-box;
          position: relative;

          &::after {
            content: "";
            display: inline-block;
            width: 28px;
            height: 18px;
            position: absolute;
            right: 0;
            bottom: 0;
            background: url("~pages/home/img/block2/selected.svg");
            background-size: 28px 18px;
          }
        }

        .ant-radio-button-wrapper::before {
          display: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background: #eff4ff;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .radioName {
        @include FlexCenter();
        min-width: 88px;
        text-align: center;
        height: 100%;
      }

      .select-category {
        @include FlexCenter();
        width: $tagWidth;
        height: $tagHeight;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 8px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #1966ff;
        border: none;
        position: relative;
        cursor: pointer;

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 16px;
          width: 24px;
          height: 24px;
          background: url("~pages/home/img/block2/selecte-category.svg")
            no-repeat;
          background-size: 24px 24px;
        }
        img {
          position: absolute;
          left: 16px;
          width: 24px;
          height: 24px;
          background-color: #ccc;
        }
      }

      .label {
        @include FlexCenter();
        justify-content: flex-start;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 112px;
        margin-right: 0;

        img {
          cursor: pointer;
          margin-left: 3px;
        }
      }

      .checkbox {
        :global {
          .auxo-checkbox-wrapper {
            @include FlexCenter;
            width: $tagWidth !important;
            height: $tagHeight !important;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 8px;
            margin-right: 24px;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: #565960;
            border: none;

            .auxo-checkbox {
              display: none;
            }
          }
          .auxo-checkbox-wrapper-checked {
           
            height: 100%;
            background: #eff4ff;
            border: 1px solid #1966ff;
            border-radius: 8px;
            color: #1966ff;
            box-sizing: border-box;
            position: relative;
            margin-left: 0;

            &::after {
              content: "";
              display: inline-block;
              width: 28px;
              height: 18px;
              position: absolute;
              right: 0;
              bottom: 0;
              background: url("~pages/home/img/block2/selected.svg");
              background-size: 28px 18px;
            }
          }
        }
      }
    }
  }

  .hotIcon {
    position: absolute;
    right: 0;
    top: 0;
    background: url('../../../../img/block2/hot-icon.svg') no-repeat center;
    background-size: 34px 16px;
    width: 34px;
    height: 16px
  }
}

@media (max-width: 1636px) {
  @mixin Item {
    font-size: 12px;
    line-height: 20px;
    width: 122px !important;
    height: 37px !important;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    margin-right: 14px;
    font-weight: 500;
    // margin-bottom: 10px;
  }

  .qualiContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .question-icon-new {
      width: 10.67px;
      height: 10.67px;
    }

    .selectShopType {
      display: flex;
      flex-direction: column;
      padding: 26px 26px 0 118px;

      :global {
        .ant-radio-button-wrapper {
          width: 128px;
          height: 40px;
          line-height: 40px;
        }
      }

      .searchBtn {
        width: 120px;
        height: 30px;
        margin-bottom: 26px;
        margin-top: 0;

        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
      }

      .formItem {
        display: flex;
        align-items: center;

        &-wrapper {
          margin-bottom: 16px;
        }

        & :global {
          .ant-radio-button-wrapper {
            @include Item;
            color: #565960;
            border: none;
          }

          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background: #eff4ff;
            border: 1px solid #1966ff;
            border-radius: 8px;
            color: #1966ff;
            box-sizing: border-box;
            position: relative;

            &::after {
              content: "";
              display: inline-block;
              width: 17px;
              height: 12px;
              position: absolute;
              right: 0;
              bottom: -1px;
              background: url("~pages/home/img/block2/selected.svg");
              background-size: 17px 12px;
            }
          }

          .ant-radio-button-wrapper::before {
            display: none;
          }

          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            background: #eff4ff;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .checkbox {
          :global {
            .auxo-checkbox-wrapper {
              @include Item;
            }
            .auxo-checkbox-wrapper-checked {
              &::after {
                content: "";
                display: inline-block;
                width: 17px;
                height: 12px;
                bottom: -1px;
                background-size: 17px 12px;
              }
            }
          }
        }

        .radioName {
          position: relative;
          @include FlexCenter();
          min-width: 88px;
          text-align: center;
          height: 100%;
        }

        .label {
          @include FlexCenter();
          justify-content: flex-start;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          text-align: center;
          color: #000000;
          width: 77px;
          margin-right: 16px;

          img {
            cursor: pointer;
            margin-left: 3px;
          }
        }
      }
    }

    .hotIcon {
      position: absolute;
      right: 0;
      top: 0;
      background: url('../../../../img/block2/hot-icon.svg') no-repeat center;
      background-size: 34px 16px;
      width: 34px;
      height: 16px;
      transform: scale(0.8);
      transform-origin: top right;
    }
  }
}
