.wrapper {
  width: 100%;
  padding-top: 64px;

  .title {
    text-align: center;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .menuBlock {
    width: 177px;
    padding-top: 12px;
    margin-right: 12px;
    color: rgb(25, 102, 255);
    background-color: #F9FBFF;
    border-radius: 12px;
    overflow-y: auto;
    overflow-x: hidden;

    .menuBody {
      border-right: none;
      background-color: #F9FBFF;
    }

    .menuItem {
      margin: 0;
      height: 52px;
      transition: all .3s;
      min-width: 136px;
      padding: 0 0 0 20px;
      display: flex;
      align-items: center;
      font-size: 17px;
    }

    :global {
      .auxo-menu-item {
        color: #565960;

        &.auxo-menu-item-selected {
          color: #1966FF;
        }
      }
      .auxo-menu-item-selected {
        border-left: 3px solid #1966FF;
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    vertical-align: baseline;
  }

  .hotSpuExtra {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 12px;
    background: #F0F6FF;
    border: 1px solid #1966FF;
    border-radius: 8px;
    cursor: pointer;

    &LeftIcon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }

    &Text {
      font-weight: 400;
      font-size: 12px;
      color: #262626;
    }

    &RightIcon {
      font-size: 12px;
    }
  }

  .mainContent {
    width: 814px;
    background-color: #F9FBFF;
  }

  .hotCategoryContent {
    padding-bottom: 12px;
  }

  .hotSpuContent {
    padding-bottom: 12px;
  }

  :global {

    .auxo-menu-item-selected {
      font-size: 16px;
      font-weight: 500;

      .auxo-sp-icon.sp-icon-parcel .icon {
        width: 1.3em;
        height: 1.5em;
        vertical-align: -.45em;
      }
    }
  }
}
