@import "../../../../statics/styles//mixins.scss";
@import "../../../../statics/styles//function.scss";

.hotCategoryWrapper {
  @include FlexCenter();
  justify-content: flex-start;
  width: 1480px;
  height: 160px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 12, 73, 0.03),
    inset 0px 1px 0px rgba(255, 255, 255, 0.41);
  backdrop-filter: blur(34px);
  border-radius: 8px;
  padding: 24px 40px 0 40px;
  box-sizing: border-box;

  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
}


$itemWidth: 270px;

.hotCategoryContainer {
  // width: 100%;
  height: 100%;
  margin: 0;

  .hotLogo {
    @include FlexCenter();
    justify-content: flex-start;
    width: 100%;
    flex: 1;

    > img {
      width: 140px;
      height: 22px;
    }
  }

  .carouselContent {
    // width: 1340px;
    width: 942px;
    height: 93px;
    position: relative;
    // margin-left: 44px;
    margin-top: 16px;
    display: flex;
    align-items: center;

    .hotLogo {
      position: absolute;
      left: -38px;
      top: 0;
    }

    .leftPrev, .rightNext {
      cursor: pointer;
    }

    .divider {
      position: absolute;
      height: 48px;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      border-color: rgba(196, 196, 196, 0.6);
      margin: 0;
    }

    .wrapper {
      width: 850px;
      margin: 0 24px;
      overflow: hidden;

      & :global {
        .auxo-carousel {
          width: 900px;
        }
      }
    }

    .cardItem {
      width: $itemWidth;
      overflow: hidden;
      box-sizing: border-box;
      color: #fff;
      position: relative;
    }

    .cardContainer {
      display: flex;
      width: $itemWidth;
      height: auto;
      margin-left: 10px;

      .image {
        width: 64px;
        height: 64px;
        border-radius: 16px;
        background: linear-gradient(326.37deg, #F3F3F3 -0.65%, rgba(243, 243, 243, 0) 124.52%);
        margin: 11px 16px 11px 11px;
      }

      .textContent {
        height: 46px;
        margin-top: 7px;
        color: #252931;
        font-style: normal;

        .title {
          font-style: normal;
          text-align: left;
          margin-bottom: 4px;
          color: #252931;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          width: 150px;
          font-size: 20px;
          line-height: 32px;
        }

        .link {
          @include FlexCenter();
          justify-content: flex-start;
          font-size: 16px;
          line-height: 28px;
          color: #1966ff;

          .arrow {
            display: inline-block;
            height: 10px;
            width: 5px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  & :global {
    .auxo-sp-icon.sp-icon-parcel .icon {
      width: 0.7rem;
    }

    .auxo-carousel .slick-dots {
      display: none !important;
    }
  }
}


@media (max-width: 1636px) {
  .hotCategoryWrapper {
    width: 986px;
    height: 112px;
    padding: 16px 0 0 26px;
    bottom: -48px;
  }  

  .hotCategoryContainer {
    width: 629px;

    .hotLogo {

      >img {
        width: 95px;
        height: 14.89px;
      }
    }

    .carouselContent {
      width: 100%;
      margin-top: 11px;
      height: unset;
  
      .leftPrev, .rightNext {
        width: 14px;
        height: 26px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
  
      .divider {
        width: 1px;
        height: 32px;
      }
  
      .wrapper {
        width: 582px;
        margin: 0;

        & :global {
          .auxo-carousel {
            width: 610px;
            flex: 1;
            margin: 0 16px;
          }
        }
      }
  
      .cardItem {
        width: 189px;
        height: 60px;
      }
  
      .cardContainer {
        width: 100%;
        height: 100%;
        margin-left: 0;

        .image {
          width: 42px;
          height: 42px;
          flex-shrink: 0;
          margin: 12px 10px;
        }
  
        .textContent {
          width: unset;
          flex: 1;

          .title {
            @include MultiLineEllipsis(1);
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            width: 86px;
            white-space: unset;
          }
  
          .link {
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
          }
        }
      }
    }
  }
}
