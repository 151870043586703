.wrapper {
  position: absolute;
  top: 65px;
  right: 0px;
  width: 460px;
  height: 265px;
  background: #fff;
  box-shadow: 4px 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 15px 16px 15px;
  transition: all .3s;
  z-index: 500;
  
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -6px;
    left: 80%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 4px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #252931;
    margin-bottom: 16px;
  }

  .qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    > img {
      width: 160px;
      height: 155px;
      margin: 0 12px;
      background: #FBFBFB;
      border-radius: 7px;
    }
  }

  .info {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #AAABAF;

    span {
      margin-right: 8px;
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    top: 50px;

    .title {
      font-size: 14px;
    }
  }
}