.logged-in-panel {
    width: 100%;
    height: 100%;
    padding: 40px 24px;
    color: #252931;
    max-width: 352px;

    &-title {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #252931;
    }

    &-content {
        margin-top: 32px;
        &-tips {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 24px;
        }
        .acount {
            width: 100%;
            padding: 10px 12px;
            border-radius: 8px;
            background-color: #F8F9FA;
            display: flex;
            align-items: center;
            font-size: 14px;
            gap: 7px;
            img {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                background-color: #1966FF;
                object-fit: none;
            }
        }
    }

    &-footer {
        margin-top: 100px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }
}