@import "~statics/styles/mixins.scss";

.goldShopCardWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  height: 100%;

  .title {
    line-height: 24px;

    img {
      height: 24px;
    }
  }

  .info {
    margin-top: 16px;

    display: flex;

    .img {
      width: 78px;
      margin-right: 8px;
      cursor: pointer;
    }

    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #25292e;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 32px;
      background: #1966ff;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 11px;
      color: #fff;
      cursor: pointer;
      overflow: hidden;
      margin-top: 10px;

      .download {
        display: flex;
        width: 128px;
        transform: translateX(-100%);
        transition: all .3s;
      }
    }
  }
}

@media (max-width: 1636px) {
  
.goldShopCardWrapper {
  margin-left: 24px;

  .title {
    line-height: 16px;

    img {
      height: 16px;
    }
  }

  .info {
    margin-top: 12px;

    .img {
      width: 54px;
      height: 54px;
      margin-right: 8px;
      cursor: pointer;
    }

    .desc {
      font-size: 12px;
    }

    .btn {
      width: 54px;
      height: 22px;
      font-size: 12px;
    }
  }
}
}