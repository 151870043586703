.wrapper {
  .title {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 0;

    &::before {
      content: "";
      width: 4px;
      height: 20px;
      background: #1966ff;
      border-radius: 8px;
    }

    &-text {
      height: 25px;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-left: 8px;
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    .title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 8px 0;
  
      &::before {
        content: "";
        width: 2px;
        height: 12px;
        background: #1966ff;
        border-radius: 8px;
      }
  
      &-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 25px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}