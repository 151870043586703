@import "~statics/styles/mixins.scss";

.bg {
  width: 100%;
  height: 714px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(287deg, rgba(255, 255, 255, 0.37) 38.4%, #FFF 113.79%);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-weight: 500;
      font-size: 36px;
      line-height: 50px;
      color: #252931;
      padding-top: 80px;
    }
  
    .subtitle {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #85878a;
      margin-top: 16px;
      margin-bottom: 80px;
    }
  }
  .wrapper{
    position: relative;
    height: 460px;
    transition: all .3s;
    flex: 1;
    margin-right: 24*0.75px;
    overflow: hidden;
  }
  .wrapper:hover{
    transform: translateY(-40px);
  }

  .content {
    width: 1480px;
    height: 400px;
    // overflow:hidden;
    // background-color:#F0F6FF ;
    // background: url("../../img/block2/settle-bg.png") no-repeat;
    background-size: 1480px 100%;
    backdrop-filter: blur(20px);
    border-radius: 16px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    
    // &-item:hover{
    //   transform: translateY(-30px);
    //   box-shadow: 0px 8px 20px rgba(69, 91, 229, 0.12);
    // }
    &-item{
      flex: 1;
      height: 356px;
      display: flex;
      padding: 40px;
      flex-direction: column;
      background-color:#FFFFFF ;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      border-radius: 12px;
      // margin-top:40px ;
      overflow: hidden;
      margin-right: 24px;
    

      &-title{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        
        >img{
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
        >div{
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #252931;
        }
      }
      &-content{
        display: flex;
        flex-direction: row;
       
        &-img{
          width: 28px;
          height: 24px;
          background: url(https://p6-open-static.ecombdimg.com/tos-cn-i-o8j9kkde8i/11453f7c7bf149e1a265d1a25b5d6822~tplv-o8j9kkde8i-1.image);
          background-size: cover;
        }
        &-right{
           margin-left: 12px;
           display: flex;
           flex-direction: column;
           
           &-subTitle{
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #252931;
           }
           &-desc{
            color:#898B8F;
            font-size: 16px;
            margin-top: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
           }
        }
      }
    }
    
    &-item:last-child{
      margin-right: 0px;
    }
  }

  .enterContent {
    @extend .content;
    margin-top: 0;
  }
}

.process {
  margin-top: 32px !important;
}

.hide {
  display: none;
}

@media (max-width: 1636px) {
  .new-enter-flow {
    transform: scale(0.8);
    margin: 0;
  }

  .bg {
    height: 525px;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background: linear-gradient(287deg, rgba(255, 255, 255, 0.37) 38.4%, #FFF 113.79%);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 50*0.75px;
        color: #252931;
        padding-top: 60*0.75px;
      }
      .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 32*0.75px;
        color: #85878a;
        margin-top: 16px;
        margin-bottom: 20px;
      }
    }
    .wrapper{
      position: relative;
      height: 390px;
      transition: all .3s;
      margin-right: 24*0.75px;
    }
    .wrapper:hover{
      transform: translateY(-40px);
    }
    .content {
      overflow: hidden;
      width: 968px;
      height: 346px;
      backdrop-filter: blur(20px);
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      
      &-item{
        margin: 40px auto;
        flex: 1;
        height: 356*0.75px;
        display: flex;
        // box-shadow: 0px 8px 20px rgba(69, 91, 229, 0.12);
        padding: 30px;
        flex-direction: column;
        // background-color:#FFFFFF ;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-radius: 12*0.75px;

        position: relative;
        overflow: hidden;
        transition: all .3s;
        
        
        &-title{
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          >img{
            width: 40*0.75px;
            height: 40*0.75px;
            margin-right: 12*0.75px;
          }
          >div{
            font-size: 24*0.75px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #252931;
          }
        }
        &-content{
          display: flex;
          flex-direction: row;
          &-img{
            width: 24*0.75px;
            height: 24*0.75px;
            background: url(https://p6-open-static.ecombdimg.com/tos-cn-i-o8j9kkde8i/11453f7c7bf149e1a265d1a25b5d6822~tplv-o8j9kkde8i-1.image) no-repeat;
            background-size: cover;
            margin-top: 4px;
          }
          &-right{
             margin-left: 9px;
             display: flex;
             flex-direction: column;
             
             &-subTitle{
              font-weight: 500;
              font-size: 12px;
              line-height: 28px;
              color: #252931;
             }
             &-desc{
              font-size: 12px;
              // margin-top: 10px;
              color:#898B8F;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
             }
          }
        }
      }
      &-item:last-child{
        margin-right: 0px;
      }
    }

    .enterContent {
      @extend .content;
      margin-top: 0;
    }
  }
}
