.wrapper {
  height: 800px;
  background-color: #fff;
  text-align: center;
  padding-top: 80px;
  > h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #252931;
  }
  > ul {
    margin: auto;
    width: 913px;
    padding: 0;
    > li {
      margin-top: 60px;
      width: 25%;
      display: inline-block;
      vertical-align: middle;
      > div {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 20px;
        background: #dce6ff center no-repeat;
        > img {
          width: 76px;
          height: 70px;
        }
      }
      > span:nth-child(2) {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #252931;
      }
    }
  }

  &-btn {
    margin-top: 80px;
    border-radius: 48px;
    height: 48px;
    padding: 13px 112px;
    line-height: 20px;
    font-size: 16px;
    color: #ffffff;
    background: #1966ff;
    border: 1px solid transparent;

    &:hover {
      background: #4784ff;
      color: #ffffff;
      border-color: transparent;
      z-index: 3;
    }
  }
}

.wrapper :global(.auxo-btn) {
  margin-top: 80px;
  border-radius: 48px;
  height: 48px;
  padding: 13px 112px;
  font-size: 16px;
}