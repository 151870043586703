.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 22;
  width: 100%;
  height: 172px;
  top: 404px;
  background: linear-gradient(
    180deg,
    rgba(246, 251, 255, 0) -9.87%,
    #f5faff 45.11%,
    rgba(245, 250, 255, 0) 94.82%
  );
  .tabView {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 480px;
    height: 61px;
    background: rgba(255, 255, 255, 1);
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 240px;
      height: 61px;
      left: 0;
      top: 0;
      border-radius: 12px;
      transition: all 0.5s;
      background: linear-gradient(90deg, #52baff 0%, #1966ff 100%);
    }

    .tab {
      z-index: 1;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tabText {
        transition: all 0.5s;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: rgba(137, 139, 143, 1);
      }
    }
    .actionTab {
      border-radius: 12px;
      .tabText {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        color: #fff;
      }
    }
  }

  .tabViewActiveTab2::before {
    transform: translateX(100%);
  }
}

@media (max-width: 1636px) {
  .tabs {
    top: 404px;

    .tabView {
      width: 380px;
      height: 40px;
      &::before {
        position: absolute;
        content: '';
        width: 190px;
        height: 40px;
        left: 0;
        top: 0;
        border-radius: 12px;
        transition: all 0.5s;
        background: linear-gradient(90deg, #52baff 0%, #1966ff 100%);
      }
      .tab {
        .tabText {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
        }
      }
      .actionTab {
        border-radius: 12px;
        .tabText {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }
  }
}
