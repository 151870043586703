.td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
}

:global {
  .ant-list-header {
    padding: 0;
    border-bottom: 0px !important;
  }
}

.listTitle {
  margin: 0px 8px;
  padding: 5px 8px;
  border-top: 1px solid #ececec;
  font-size: 12px;
  line-height: 16px;
  color: #969696;
}

.listBox {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 364px;
  // width: 166px;
  flex: 1;
  border-left: 1px solid #edeef0;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    // width: 166px;
    height: 32px;
    line-height: 20px;
    padding: 0 12px 0 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #12141a;
    cursor: pointer;

    &:hover {
      background: #e6eeff;
    }

    .itemLabel {
      display: flex;
      align-items: center;
      flex: 1;
      width: 122px;
    }

    .itemArraw {
      width: 16px;
      height: 16px;
    }

    span {
      max-width: 122px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .itemName {
      flex: 1;
      height: 32px;
      line-height: 32px;
      padding: 0 8px;
    }
  }
  .active {
    background: #e6eeff;
  }

  .text {
    span {
      max-width: 168px;
    }
  }
}
//.border{
//  border-left: 1px solid #EDEEF0;
//}

.emptyList {
  border-left: 1px solid #edeef0;
  height: 364px;
  // width: 166px;
  flex: 1;
}

