.larkGroupModuleWrapper {
  height: 570px;
  background-color: #fff;

  .larkGroupModuleTitle {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 1);
  }


  .moduleContainer {
    margin: 0 auto;
    width: 1400px;
    height: 400px;
    background: url("../../assets/lark_group_module_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .wayButton {
      width: 64px;
      height: 32px;
      border-radius: 16px;
      margin-bottom: 16px;
    }

    .text {
      font-size: 24px;
      font-family: "PingFang HK";
      letter-spacing: 1px;
      font-weight: 500;
    }

    .downloadButton {
      font-size: 20px;
      margin-top: 24px;
      width: 100%;
      text-align: center;

      span {
        font-size: 24px;
      }
    }

    .moduleOne {
      width: 400px;
      height: 342px;
      border-radius: 6px;
      padding: 40px 40px 32px;
      background: linear-gradient(180deg, rgba(255, 255, 255, .8) .75%, rgba(255, 255, 255, .2) 115.25%);
      margin-right: 40px;

      .settleButton {
        margin-top: 80px;
        width: 320px;
        height: 60px;
        font-size: 24px;
        border-radius: 8px;
      }
    }

    .moduleTwo {
      width: 400px;
      padding: 40px 40px 32px;
      height: 342px;
      border-radius: 6px;
      background: linear-gradient(180deg, rgba(255, 255, 255, .8) .75%, rgba(255, 255, 255, .2) 115.25%);
      margin-right: 60px;

      .qrCodeContainer {
        margin-top: 24px;
        width: 320px;
        height: 116px;
        background-color: rgba(255, 255, 255, 1);
        display: flex;

        .qrCode {
          margin: 10px 24px 10px 10px;

          img {
            width: 100px;
            height: 96px;
          }
        }

        .qrCodeText {
          color: rgba(127, 127, 127, 1);
          font-size: 20px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;

          .item {
            line-height: 28px;
          }
        }
      }
    }
  }
}

@media (max-width: 1636px) {

  .larkGroupModuleWrapper {
    height: 408px;

    .larkGroupModuleTitle {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 24px;
    }

    .moduleContainer {
      width: 72.9%;
      height: 268px;
      border-radius: 10px;

      .wayButton {
        width: unset;
        height: 21px;
        border-radius: 10px;
        margin-bottom: 10px;
        font-size: 10px;
        padding: 2px 5px;
        min-width: 42.6px;
      }

      .text {
        font-size: 16px;
      }

      .downloadButton {
        font-size: 16px;
        margin-top: 14px;

        span {
          font-size: 14px;
        }
      }

      .moduleOne {
        width: 28.5%;
        height: 228px;
        border-radius: 4px;
        padding: 26.6px 26.6px 20px;
        margin-right: 26.6px;

        .settleButton {
          margin-top: 50px;
          width: 100%;
          height: 40px;
          font-size: 16px;
          border-radius: 4px;
        }
      }

      .moduleTwo {
        width: 28.5%;
        height: 228px;
        border-radius: 4px;
        padding: 26.6px 26.6px 20px;
        margin-right: 40px;

        .qrCodeContainer {
          margin-top: 16px;
          width: 100%;
          height: 77px;

      
          .qrCode {
            margin: 8px 22px 8px 8px;

            img {
              width: 63px;
              height: 100%;
            }
          }

          .qrCodeText {
            font-size: 14px;

            .item {
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
