.rightWrapper {
  background-color: rgba(246, 250, 255, 1);

  .title {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 80px;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    color: rgb(0, 0, 0);
  }

  .moreDetail {
    margin: 0 auto;
    width: 1398px;
    position: relative;

    .link {
      display: flex;
      align-items: center;
      position: absolute;
      top: -40px;
      right: 0;
    }
  }

  .cardContainer {
    width: 1398px;
    display: flex;
    margin: 0 auto;
  }

  .verifyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 40px;

    img {
      height: 50px;
    }

    .verifyButton {
      margin-top: 16px;
      border-radius: 8px;
      font-size: 24px;
      width: 400px;
      height: 60px;
    }
  }

}

@media (max-width: 1636px) {

  .rightWrapper {

    .title {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 24px;
      padding-top: 40px;
    }

    .moreDetail {
      width: 932px;

      .link {
        top: -40px;
        right: 0;
      }
    }

    .cardContainer {
      width: 932px;
    }

    .verifyContainer {
      margin-top: 8px;

      img {
        height: 34px;
      }

      .verifyButton {
        margin-top: 14px;
        border-radius: 6px;
        font-size: 16px;
        width: 266px;
        height: 40px;
      }
    }
  }

}