.merchantRightCard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  .item {
    padding: 24px;
  }

  .img {
    width: 72px;
    height: 72px;
  }

  .name {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: rgba(0, 0, 0, .85);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 16px;
  }

  .introduce {
    color: rgba(127, 127, 127, 1);
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    width: 100%;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
  }


  .cardContainer {
    width: 450px;
    height: 260px;
    background-color: #fff;
    border-radius: 12px;
  }

  .cardContainer:not(:nth-child(3n)) {
    margin-right: 24px;
    margin-bottom: 24px;
  }

}

@media (max-width: 1636px) {
  .merchantRightCard {

    .item {
      padding: 16px;
    }

    .img {
      width: 50.6px;
      height: 50.6px;
    }

    .name {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 10px;
    }

    .introduce {
      font-size: 14px;
      line-height: 20px;
    }

    .cardContainer {
      width: 300px;
      height: 174px;
      border-radius: 8px;
    }

    .cardContainer:not(:nth-child(3n)) {
      margin-right: 16px;
      margin-bottom: 16px;
    }

  }

}