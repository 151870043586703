.liveMenu {
  width: 21vw;
  min-width: 270px;
  margin-left: 16px;
  padding: 0 20px;
  background: #202124;
  border-radius: 6px;

  .header {
    display: flex;
    justify-content: space-between;
    height: 62px;
    align-items: center;
    color: #fff;
    border-bottom: 1px solid #343434;

    &Main {
      font-size: 18px;
    }

    &More {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .content {
    max-height: calc(100% - 42px);
    overflow: scroll;
  }

  .menu {

    &Item {
      display: flex;
      margin: 12px 0;
      padding: 6px;
      background-color: #2C2D30;
      border-radius: 6px;
      cursor: pointer;
    }

    &Img {
      width: 58px;
      height: 58px;
      border-radius: 6px;
      object-fit: cover;
    }

    &Main {
      margin-left: 12px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &Title {
      color: #fff;
      font-size: 12px;
    }

    &Btn {
      align-self: flex-end;
    }
  }

}

@media (max-width: 1636px) {
  .liveMenu {
    width: 280px;

    .header {
      height: 42px;

      &Main {
        font-size: 14px;
      }
  
      &More {
        font-size: 12px;
      }
    }

    .content {
      height: calc(100% - 60px);
    }
  }
}
