.squareButton {
  width: 72px;
  height: 72px;
  padding: 6px;
  background: rgba(40, 40, 40, 0.95);
  border-radius: 8px;
  text-align: center;
  color: #fff;
  cursor: pointer;

  .icon {
    font-size: 24px;
  }

  .text {
    font-size: 16px;
    line-height: 1;
  }


  &:not(:only-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 1636px) {
  .squareButton {
    padding: 4px;
    width: 48px;
    height: 48px;

    .icon {
      font-size: 16px;
    }
  
    .text {
      font-size: 12px;
    }
  }
}
