@import "./var";

@mixin FlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin FlexSpaceBetween {
    @include FlexCenter();
    justify-content: space-between;
}

@mixin FlexColumn {
    @include FlexCenter();
    flex-direction: column;
}

@mixin FlexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin FlexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin MultiLineEllipsis($line) {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}