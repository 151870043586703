.td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
}

.listBox {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 364px;
  flex: 1;

  .item {
    span{
      flex: 1;
      display: block;
      max-width: 126px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    display: flex;
    justify-content: space-between;
    flex-direction:row;
    align-items: center;
    cursor: pointer;

    // width: 166px;
    width: 100%;
    height: 32px;
    padding: 0 12px 0 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #12141a;
    &:hover{
      background: #E6EEFF;
    }
  }
  .active{
    background: #E6EEFF;
  }
}
.border{
  border-right: 1px solid #EDEEF0;
}


.emptyList {
  height: 364px;
  // width: 166px;
  flex: 1;
}
