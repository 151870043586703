.instantRetailLogin {
  :global {
    .account-center-sdk-form {
      margin-top: 56px !important;
    }
  }

  .agreement {
    margin-top: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #aaabaf;
    a {
      color: #55585c;
    }
  }
}
