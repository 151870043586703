.guideWrapper {
  position: relative;
  z-index: 51;
  cursor: pointer;
  width: 100%;

  .img {
    display: block;
    width: 1160px;
    height: 100px;
    margin: 0 auto;
    cursor: pointer;
  }
}
