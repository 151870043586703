.zeroTag {
  height: 18px;
  background: #FFF2EB;
  border-radius: 4px;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  color: #FF5C00;
  padding: 0 4px;
}

.zeroTips {
  width: 210px;
  color: #000;
  background: #FFFFFF;
  border-radius: 4px;
  flex-wrap: wrap;
  padding: 12px 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  .zero {
    color: #FF8413;
  }
}
