.show-board {
  margin-top: 16px;
}

.quail-fee-cn-container {
  width: 1160px;
  padding: 32px 32px 0 32px;
  background-color: #fff;
  border-radius: 4px;
  min-height: 264px;
}

.title {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    // position: absolute;
    display: inline-block;
    width: 32px;
    height: 6px;
    background: linear-gradient(270deg, #1966FF 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 3px;
    margin-right: 8px;
  }

  &::after {
    content: '';
    // position: absolute;
    display: inline-block;
    width: 32px;
    height: 6px;
    background: linear-gradient(270deg, #1966FF 0%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(180deg);
    border-radius: 3px;
    margin-left: 8px;
  }
}

.search-form {
  margin-top: 14px;
}

.flex {
  display: flex;

  .wrapper {
    width: 712px;
    padding-bottom: 8px;
  }
}

.img {
  cursor: pointer;
  margin-left: 12px;
  width: 432px;
  // height: 232px;
  background-color: #FFFFFF;
}

.btn {
  background-color: #1966ff;
  border-radius: 24px;
  width: 176px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &-wrap {
    display: flex;
    justify-content: center;
    // margin-top: 16px;
    background-color: #fff;
    width: 1160px;
    padding-bottom: 32px;
  }
}

:global {
  #webpack-hot-middleware-clientOverlay {
    display: none;
  }
}
