.downloadLoginModal {

  :global {

    .auxo-modal-body {
      padding: 0;
    }

    .auxo-modal-content:not(.sp-null) {
      border-radius: 8px;
    }

    .auxo-modal-close-x {
      width: 32px;
      height: 40px;
      line-height: 40px;
    }

  }
}