@import '~statics/styles/mixins.scss';

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 501;
  background-color: rgba($color: #000000, $alpha: 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    display: flex;
    height: 480px;
    background-color: #fff;
    box-shadow: 0px 6px 32px rgba(53, 76, 166, 0.11);
    border-radius: 8px;
    overflow: hidden;

    .steer {
      border-radius: 4px;
      margin: 0 40px;
      width: 360px;

      &-wrapper {
        flex: 1;
        padding: 46px 0 10px 0;
        background: #CDE2FF;
        background: url('../../img/operate-book/bg.png') no-repeat;
        background-size: 100% 104%;
      }

      &-item {
        display: flex;
        height: 66px;
        margin-bottom: 25px;

        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;

          position: absolute;
          bottom: -19px;
          left: 28px;

          background: url('../../img/operate-book/arrow.svg') no-repeat;
          background-size: 12px 12px;
        }

        &:last-child::after {
          display: none;
        }

        &-icon {
          width: 66px;
          height: 66px;
          filter: drop-shadow(0px 6px 24px #D5E6FF);
          background-color: #fff;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-num {
          height: 100%;
          font-weight: 700;
          font-size: 24px;
          line-height: 98.41%;
          background: linear-gradient(147.02deg, rgba(71, 132, 255, 0.8) 29.88%, #4784FF 68.96%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          flex-shrink: 0;
          transform: translateY(-7px);
          margin-right: 8px;
          padding-top: 15px;

          >img {
            width: 18px;
            height: 18px;
          }
        }

        &-info {
          height: 100%;
          padding-top: 10px;

          &-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.8);
          }

          &-desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: flex-start;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 5px;
            height: 40px;
          }
        }
      }

      &-title {
        @include FlexCenter;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;

        background: linear-gradient(153.58deg, rgba(25, 102, 255, 0.85) 27.69%, rgba(25, 102, 255, 0.95) 32.06%, #1966FF 72.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        margin-bottom: 22px;
      }
    }


    /* 已登录未提审登录 */
    .login {
      width: unset;
      height: unset;
      position: relative;

      &-wrapper {
        width: 422px;
      }

      & :global {
        .ecomLoginBox {
          width: unset;
          height: 345px;
          box-shadow: unset;
        }

        .account-center-message {
          pointer-events: none;
        }

        .account-center-sdk-container {
          width: 370px;
          margin-left: 25px;
          margin-top: 10px;
        }

        .account-center-sdk-form {
          margin-top: 30px !important;
        }

        .account-center-sdk-form .account-center-input-row  {
          margin-bottom: 10px;
        }
      }
    }


    .tips {
      display: flex;
      width: 374px;
      margin: 15px auto 0 auto;
      padding: 10px 10px 10px 0;

      background: #FAFCFF;
      border: 0.5px solid #E3EEFF;
      border-radius: 4px;

      &-icon {
        // width: 28px;
        // height: 32px;
        width: 56px;
        height: 64px;
        // background-color: #1966FF;
        flex-shrink: 0;
        margin-right: 10px;

        &-box {
          width: 40px;
        }
      }
    }

    /* 未登录 */
    .not-login {
      width: unset;
      height: unset;
      position: relative;

      & :global {
        .ecomLoginBox {
          width: unset;
          height: 100%;
          box-shadow: unset;
        }

        .account-center-sdk-container {
          width: 370px;
          margin-left: 25px;
          margin-top: 10px;
        }

        .account-center-sdk-form {
          margin-top: 30px !important;
        }

        .account-center-sdk-form .account-center-input-row  {
          margin-bottom: 15px;
        }

        .account-center-submit {
          margin-top: 30px;
        }
      }

      .agreement {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 110px;
      }
    }

    .close {
      position: absolute;
      right: 24px;
      top: 24px;
      font-size: 28px;
      z-index: 11;
      background: url("~statics/img/login/close.svg") no-repeat;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

.agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #AAABAF;

  a {
    color: #55585C;
  }
  .unable_to_sign_in {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #1966FF;
    margin-left: 8px;
    cursor: pointer;
  }
}

@media (max-width: 1636px) {
  .wrapper {
    .content {
      transform: scale(0.7);
      transform-origin: center;
    }
  }
}
