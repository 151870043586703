.successPageContainer {
  height: 286px;
  background: linear-gradient(180deg, #9eef79 -10.22%, #fff 35.04%), #fff;
  border-radius: 8px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  text-align: center;

  .icon {
    margin-bottom: 24px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: rgba(37, 41, 49, 1);
  }

  .subTitle {
    margin-bottom: 24px;
  }

  .settleButton {
    width: 224px;
    height: 30px;
    margin-bottom: 10px;
  }
}

.successPageModal {

  :global {

    .auxo-modal-content {
      border-radius: 8px;
    }
  }

  .formContainer {
    height: 100%;
    width: 968px;
  }

}

.veifyPageContainer {
  background-image: url("https://lf3-static.bytednsdoc.com/obj/eden-cn/uvpahylwvauhojylt_lm_tvjl/ljhwZthlaukjlkulzlp/fe-merchant-settle/gold-form-bg.png");
  background-repeat: no-repeat;
}
