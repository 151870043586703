@import "../../statics/styles/mixins.scss";

$height: 60px;
$width: 290px;

.goldShopContainer {
  padding-bottom: 80px;
  position: relative;
}

.recruitmentModule {
  height: 795px;
  width: 100%;
  background-image: url("./assets/pickForm/pick_form_bg_1920.png");
  padding-top: 80px;
  background-repeat: no-repeat;
  background-size: 100%;

  .blockOne {
    margin: 0 8.3%;
    height: 714px;
    width: 1599px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-wrap: wrap;
    position: relative;


    .left {
      width: 49%;
    }

    .divider {
      height: 318px;
      border-color: rgba(230, 240, 255, 1);
      margin-top: 128px;
      border-width: 2px;
    }

    .right {
      width: 49%;
    }

    .titleImg {
      height: 112px;
      width: 100%;
      display: flex;
      margin-top: 60px;
      justify-content: center;

      img {
        height: 100%;
      }
    }
  }
}

.larkGroupCard {
  position: fixed;
  right: 0;
  top: 612px;
}

.larkGroupModule {
  margin-top: 80px;
}

.footerContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, .7);
  backdrop-filter: blur(6px);
  z-index: 2;

  .verifyButton {
    @include FlexCenter();
    position: relative;
    font-size: 24px;
    line-height: 20px;
    border: 1px solid rgba(25, 102, 255, 1);
    color: rgba(25, 102, 255, 1);
    width: $width;
    height: $height;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    margin-right: 40px;
  }

  .verifyButton:active {
    border: 1px solid rgba(25, 102, 255, 1);
    color: rgba(25, 102, 255, 1);
  }

  .settleBtn {

    div {
      font-weight: 400;
      background: #1966ff;
      border-radius: 8px;
    }
  }

}

@media (max-width: 1636px) {

  .recruitmentModule {
    background-image: url("./assets/pickForm/pick_form_bg_1280.png");
    height: 530px;
    width: 100%;
    padding-top: 54px;

    .blockOne {
      margin: 0 8.3%;
      height: 476px;
      border-radius: 8px;
      width: unset;

      .divider {
        height: 144px;
        margin-top: 114px;
      }

      .titleImg {
        height: 78px;
        margin-top: 40px;
      }
    }
  }

  .larkGroupCard {
    position: fixed;
    right: 0;
    top: 392px;
  }

  .footerContainer {

    .verifyButton {
      font-size: 16px;
      line-height: 16px;
      width: 194px;
      height: 40px;
      border-radius: 6px;
      margin-right: 20px;
    }

    .verifyButton:active {
      border: 1px solid rgba(25, 102, 255, 1);
      color: rgba(25, 102, 255, 1);
    }

    .settleBtn {

      div {
        border-radius: 6px;
      }
    }

  }
}
