.successPage {
  padding: 60px 24px 0;
  text-align: center;
  background-image: url("./bg.svg");
  background-size: contain;
  background-repeat: no-repeat;

  .topBg {
    height: 100px;
    width: 100%;
  }

  .title {
    padding-bottom: 16px;
    color: rgba(37, 41, 49, 1);
    font-size: 22px;
  }

  .hasRegistered {
    padding-top: 32px;
  }

  .tip {
    text-align: left;
    font-size: 20px;
    width: 500px;
    margin: 0 auto;
  }

  .text {
    margin-top: 24px;
    border-radius: 8px;
    background: linear-gradient(rgba(237, 242, 255, .6),rgba(255,255,255,1));
    height: 84px;
    color: rgba(37, 41, 49, 1);
    line-height: 84px;
    font-size: 20px;
  }

  .btn {
    margin-top: 24px;
    height: 40px;
    width: 240px;
    border-radius: 6px;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
