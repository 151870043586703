.appointmentModal {

  :global {

    .auxo-modal-body {
      padding: 0;
    }

    .auxo-modal-close {
      top: 12px;
    }
  }
}
