.block7 {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: linear-gradient(89.89deg, #FBFCFF 1.04%, rgba(255, 255, 255, 0) 51.92%, rgba(255, 255, 255, 0) 64.02%, rgba(255, 255, 255, 0.6) 100.94%);
  padding: 80px 0 40px 0;
  .title {
    font-size: 42px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    margin-bottom: 50px;
    color: #000;
  }
  .content {
    display: flex;
    width: 1480px;
    justify-content: space-between;
  }
  .card {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 12px;
    padding: 0 24px;
    &:first-of-type {
      background: linear-gradient(355.85deg, rgba(245, 244, 254, 0) 3.38%, rgba(245, 242, 255, 0.8) 101%);
      border: 1px solid;
      border-image-source: linear-gradient(180deg, rgba(207, 152, 255, 0.06) 0%, rgba(218, 178, 255, 0) 100%);
    }
    &:nth-of-type(2) {
      background: linear-gradient(355.85deg, rgba(245, 244, 254, 0) 3.38%, #FFF8EF 102.83%);
      border: 1px solid;
      border-image-source: linear-gradient(180deg, rgba(255, 235, 215, 0.5) 0%, rgba(253, 248, 243, 0) 100%);
    }
    &:nth-of-type(3) {
      background: linear-gradient(355.88deg, rgba(245, 244, 254, 0) 3.35%, #F0FAF5 101.12%);
      border: 1px solid;
      border-image-source: linear-gradient(180deg, rgba(173, 247, 219, 0.3) 0%, rgba(173, 247, 219, 0) 100%);
    }
    &:nth-of-type(4) {
      background: linear-gradient(352.41deg, rgba(244, 249, 254, 0) 5.88%, #F1F8FF 98.43%);
      border: 1px solid;
      border-image-source: linear-gradient(180deg, rgba(90, 184, 254, 0.1) 0%, rgba(38, 131, 251, 0) 100%);
    }
    .cardTitle {
      font-size: 28px;
      font-weight: 500;
      line-height: 48px;
      text-align: center;
      margin-top: 8px;
      color: rgba(0, 0, 0, 0.85);
    }
    .cardDesc {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }
    img {
      width: 234px;
      height: 480px;
      border-radius: 12px;
      margin-top: 12px;
    }
  }
}

@media (max-width: 1636px) {
  .content {
    width: 1480px;
    scale: 0.8;
  }
}
