.wrapper {
  background: linear-gradient(180deg, #e7eefc 0%, #e7effc 100%);
  padding: 80px 0;
  > h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #252931;
    text-align: center;
  }
  > .main {
    width: 1068px;
    margin: 40px auto;
    background: #f5f6f7;
    border-radius: 12px;
    border: 1px solid #edeeef;
    box-shadow: 0px 6px 32px rgba(53, 76, 166, 0.05);
  }
  > .tips {
    width: 666px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    > span {
      cursor: pointer;
      color: #1966ff;
    }
  }
}

.panel {
  display: flex;
  height: 540px;
  background-color: #fff;
  position: relative;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
}

.wrapper .main .panel :global(.auxo-tabs) {
  width: 181px;
  border-right: 1px solid #edeeef;
  height: 540px;
  text-align: center;
  padding-top: 40px;
  background: #f5f6f7;
}

.wrapper .main .panel :global(.auxo-tabs-nav) {
  width: 100%;
}

.wrapper .main .panel :global(.auxo-tabs-tab) {
  width: 104px;
  padding: 8px 0px;
  border-radius: 36px;
  margin: 0 auto 16px;
}

.wrapper .main .panel :global(.auxo-tabs-nav .auxo-tabs-tab.auxo-tabs-tab-active) {
  background-color: #dfe8f8;
}

.wrapper .main .panel :global(.auxo-tabs-tab:first-child) {
  margin-top: 0;
}

.wrapper .main .panel :global(.auxo-tabs-tab-btn) {
  margin: 0 auto;
}

.panel :global(.auxo-tabs-tab:not(:first-child)) {
  margin-top: 16px;
}

.right {
  position: relative;
  width: 885px;
  height: 540px;
  margin: 0 auto;
}

.panel :global(.auxo-tabs-content-holder) {
  display: none;
}

.panel :global(.auxo-tabs > .auxo-tabs-nav .auxo-tabs-nav-operations) {
  display: none;
}

.panel :global(.auxo-tabs-left > .auxo-tabs-nav .auxo-tabs-nav-wrap::before),
.panel :global(.auxo-tabs-left > .auxo-tabs-nav .auxo-tabs-nav-wrap::after) {
  display: none;
}

.cell {
  position: relative;
  width: 885px;
  height: 54px;
  background: #ffffff;
  line-height: 53px;
  padding: 0 306px 0 20px;
  border-bottom: 1px solid #edeeef;
  font-size: 14px;
  color: #12141a;
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: rgba(25, 102, 255, 0.1);
    .action {
      display: block;
    }
  }
}

.cell .action {
  display: none;
  position: absolute;
  line-height: 53px;
  top: 50%;
  right: 64px;
  transform: translateY(-50%);
}

.cell :global(.auxo-btn) {
  border-radius: 36px;
  height: 36px;
  padding: 8px 23px;
}

.cell :global(.auxo-btn-dashed) {
  background-color: transparent !important;
  margin-right: 8px;
}

.searchBox {
  height: 61px;
  border-bottom: 1px solid #edeeef;
  text-align: center;
  & :global(.auxo-input-affix-wrapper) {
    height: 40px;
    border-radius: 40px;
    padding-left: 16px;
    width: 600px;
    margin: 10px auto;
    text-align: left;
  }

  & :global(.auxo-sp-icon) {
    color: #979797;
    font-size: 14px;
  }
}

.empty {
  text-align: center;
  width: 100%;
  > img {
    width: 226px;
    height: 140px;
  }
  > div {
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    margin: -8px auto;
  }
}

.spin {
  font-size: 28px;
  color: #b9babd;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 28px;
  height: 28px;
}
