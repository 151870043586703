@import "~statics/styles/mixins.scss";

.wrap {
  width: 456px;
  flex-shrink: 0;
  border-radius: 16px;
  position: relative;
  &:first-of-type {
    .content {
      margin-left: 0;
    }
  }
  &:last-of-type {
    .content {
      padding-right: 0;
    }
  }
  .content {
    padding-right: 40px;
    margin-left: 48px;
    position: relative;
    height: 100%;

    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 80%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #B9DDFF;
    }

    .enterWrap {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin-bottom: 32px;
    }
    &-enter {
      &::after {
        height: 100%;
      }
    }
  }

  &:nth-child(3n) {
    .content::after {
      display: none;
    }
  }
}

.enterFlowStep {
  @include FlexCenter;
  justify-content: space-between;
  padding-bottom: 26px;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 13.4px;
    top: 38px;
    width: 0;
    border: 1px dashed #1966FF;
    height: 50%;
    opacity: .4;
  }

  &:last-child{
    padding-bottom: 0;

    &::before {
      display: none
    }
  }

  &-left {
    width: 100%;

    &-title {
      @include FlexCenter;
      justify-content: flex-start;

      &-num {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #1966ff;
        margin-right: 11px;
      }

      &-text {
        @include MultiLineEllipsis(2);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        margin-right: 8px;
      }

      &-tag {
        background: #ffffff;
        padding: 5px 7px;
        font-size: 12px;
        line-height: 10px;
        text-align: center;
        color: #1966ff;
        background: rgba(25, 102, 255, 0.08);
        border-radius: 4px;
      }
    }

    &-desc {
      @include MultiLineEllipsis(2);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #898B8F;
      margin-top: 10px;
      margin-left: 40px;
      max-width: 400px;
    }
  }

  &-right {
    @include FlexCenter;
    flex-direction: column;
    justify-content: flex-end;

    &-btn {
      @include FlexCenter;

      border: 1px solid #1966ff;
      border-radius: 3.83244px;
      box-sizing: border-box;
      padding: 3px 10px;

      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #1966ff;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        color: #4784FF;
        border: 1px solid #4784FF;
      }
    }
  }
}


.title {
  @include FlexCenter;
  @include MultiLineEllipsis(1);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #292B33;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 4px;
}

.desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #565960;
  margin: 12px auto 30px auto;
  //@include MultiLineEllipsis(2);
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexCenter {
  @include FlexCenter;
  // display: flex;
  // align-items: center;
}

.hotIcon {
  background: url('../../assets/hot-icon.svg') no-repeat center;
  background-size: 47px 20.55px;
  display: inline-block;
  width: 47px;
  height: 20.55px;
  margin-left: 8px;
}

@media (max-width: 1636px) {
  .wrap {
    // width: 307px;
    flex: 1;
    // padding: 12px;
    // margin: 0 12px;

    .content {
      padding-right: 24px;
      margin-left: 24px;

      &::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 80%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #B9DDFF;
      }

      &-enter {
        &::after {
          height: 100%;
        }
      }
    }

    &:nth-child(1){
      .content {
        margin-left: 0;
      }
    }
    &:nth-child(3) {
      .content {
        padding-right: 0;
      }
      .content::after {
        display: none;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .icon {
    width: 26px;
    height: 26px;
  }

  .desc {
    font-size: 12px;
    line-height: 16px;
    margin: 8px auto 16px auto;
  }

  .enterFlowStep {
    padding-bottom: 21px;
    // margin-bottom: 16px;

    &::before {
      left: 9.5px;
      top: 25px;
    }

    &-left-title {
      &-num {
        font-size: 14px;
        line-height: 20px;
        margin-right: 9px;
      }

      &-text {
        font-size: 14px;
        line-height: 20px;
      }

      &-tag {
        font-size: 12px;
        line-height: 16px;
        padding: 0 4px;
      }
    }

    &-left-desc {
      margin-top: 4px;
      margin-left: 28px;
      font-size: 12px;
      line-height: 16px;
    }

    &-right-btn {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .hotIcon {
    margin-left: 0px;
    transform: scale(.8);
    vertical-align: sub;
    position: relative;
    top: 1px;
  }
}
