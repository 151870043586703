@import "~statics/styles/mixins.scss";

.wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1480px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-top: -60px;
  z-index: 10;
  &:after {
    content: ' ';
    position: absolute;
    top: -80px;
    width: 100vw;
    height: 100px;
    z-index: -1;
    background: linear-gradient(180deg, rgba(246, 251, 255, 0) 0%, #FDFEFF 70.43%) no-repeat center top/ 100% 100px;
  }
}

.paddingTop {
  padding-top: 142px;
}

.list {
  display: flex;
  justify-content: space-between;
}

.tips {
  width: 520px;
  height: 42px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #898B8F;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url('./assets/tips.svg') no-repeat center;
    background-size: 14px;
    margin-right: 9px;
  }
}

.settleButton {
  background-color: unset;
  padding: 0;
  padding-top: 34px;
}

.bgImgs {
  z-index: 99;
  margin-top: 52px;
  transform: translateY(-10px);
  .imgViews {
    position: relative;
    height: 178px;
    width: 1210px;
    overflow: hidden;
    padding: 2px;
    margin: 0 auto;
    .img1s {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .img2s {
      position: absolute;
      left: 428px;
      top: 0;
      height: 100%;
    }
  }
  .imgTexts {
    position: absolute;
    left: 0;
    top: 0;
    padding: 24px;
  }
  .titles {
    font-family: PingFang SC;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 16px;
    color: rgba(37, 41, 49, 1);
  }
  .texts {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: rgba(94, 96, 102, 1);
    width: 80%;
  }
}

@media (max-width: 1636px) {
  .bgImgs {
    transform: translateY(-60px);
    .imgViews {
      width: 1184px;
      .img2s {
        position: absolute;
        left: 402px;
        top: 0;
        height: 100%;
      }
    }
  }
  .list {
    width: 1480px;
    scale: 0.8;
  }
  .wrap {
    margin-top: -130px;
    &:after {
      width: 1636px;
      top: -24px;
    }
  }
  .paddingTop {
    padding-top: 92px;
  }
}

