.title1 {
  margin-bottom: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #12141a;
}

.title2 {
  margin-bottom: 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #85878c;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .download-btn {
    position: relative;
    top: -15px;
  }
}

.table{
  border-radius: 4px;
  thead{
    background: #F9F9FA;
    box-shadow: 0px 1px 0px #EDEEF0;
    font-size: 14px;
    line-height: 20px;
    th{
      height: 52px;
      padding-left: 16px;
      color: #85878A;
      position: relative;
      &:after{
        position: absolute;
        border-right: 1px solid #EEEFF0;
        content: '';
        top: 51px;
        left: 0;
        height: 40px;
      }
      &:last-child:before{
        position: absolute;
        border-right: 1px solid #EEEFF0;
        content: '';
        top: 51px;
        left: 122px;
        height: 40px;
      }
    }
  }
  .thWidth1{
    width: 138px;
  }
  .thWidth2{
    width: 224px;
  }
  .thWidth3{
    width: 388px;
  }
  .thWidth4{
    width: 122px;
  }
  border: 1px solid #EEEFF0;
  //box-sizing: border-box;
  border-radius: 4px;
  tbody{
    th {
      border-right: 1px solid #EEEFF0;
    }
    //tr td{
    //  box-shadow: 0px 1px 0px #EDEEF0;
    //}
    td{
      border-right: 1px solid #EEEFF0;
      box-shadow: 0px 1px 0px #EDEEF0;
      height: 52px;
      padding: 16px;
    }
    .del{
      color: #1966FF;
      cursor: pointer;
      &:after{
        position: absolute;
        border-right: 1px solid #EEEFF0;
        content: '';
        top: -19px;
        left: -1px;
        height: 20px;
      }
    }
    .level{

    }
  }

  tr,td,th{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #12141A;
  }
}
