.wrap {
  width: 330px;
  height: 246px;
  background-color: #fff;
  padding: 38px 15px 35px 26px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-right: 24px;

  &:nth-child(4n) {
    margin-right: 0;
  }
}

.fast {
  background: linear-gradient(214.38deg, #FFF8ED 10.61%, #FFFCF7 77.41%);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background: url('./assets/fast-icon.svg') no-repeat center;
    background-size: 133px 36px;
    width: 133px;
    height: 36px;
  }
}

.icon {
  width: 76px;
  height: 76px;
}

.name {
  margin-top: 5px;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;
  color: #000000;
}

.desc {
  margin-top: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #7F7F7F;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 1636px) {

  .wrap {
    width: 220px;
    height: 164px;
    padding: 17px 10px 22px 17px;
    margin-bottom: 24px;
    margin-right: 24px;
  }

  .fast::before {
    width: 88px;
    height: 24px;
    background-size: 88px 24px;
  }

  .icon {
    width: 50px;
    height: 50px;
  }
  
  .name {
    margin-top: 3px;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
  }
  
  .desc {
    font-size: 12px;
    line-height: 19px;
    margin-top: 10px;
  }
}