.wrapper {
  border-radius: 8px;
  overflow: hidden;
}

.modal {
  width: 968px;
  height: 759px;
  border-radius: 8px;
}

.close-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

@media (max-width: 1636px) {
  .modal {
    width: 968px;
    height: 759px;
    border-radius: 8px;
  }
}