.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  width: 630px;
  height: 608px;

  background: url('./assets/bg.jpeg') no-repeat center;
  background-size: 630px 608px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(12 40 103 / 10%);
  padding-top: 72px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  top: 31px;
  right: 34px;
  background: url('./assets/close.svg') no-repeat center;
  background-size: 24px;
  width: 24px;
  height: 24px;

  cursor: pointer
}

.desc {
  margin: 0 auto;
  text-align: center;

  > div {
    font-size: 24px;
    line-height: 32px;
    color: #252931;
  }
}

.btn {
  @extend .flexCenter;
  cursor: pointer;
  margin-top: 28px;
  width: 246px;
  height: 33px;
  border: 1px solid #1966FF;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #1966FF;

  &-active {
    background: #1966FF;
    color: #fff;
  }

  &-box {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 378px;
    height: 163px;
    // background-color: #008c8c;
    margin-top: 36px;
    padding-top: 43px;
    border-radius: 6px;
    background-size: 378px 163px;
    background-repeat: no-repeat;
    background-position: center;

    img {
      height: 22px;
      width: auto;
    }
  }
}

.btnWrap {
  @extend .flexCenter;
  flex-direction: column;
}

.link {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-top: 10px;
  color: #1966FF;
  cursor: pointer;
}

@media (max-width: 1636px) {
  .popup {
    transform: translate(-50%, -46%) scale(0.8);
  }
  
}