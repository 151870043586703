@import "~statics/styles/mixins.scss";

@mixin FontStyle {
  font-family: PingFang SC;
  font-style: normal;
  letter-spacing: 0;
}

.empty_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 175px;

  &_img {
    width: 135px;
    height: 90px;
    margin-top: 20px;
  }

  &_text {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #252931;
    margin-top: 20px;
  }
}

.wrapper {
  position: relative;
  min-height: 405px;
  width: 466px;
  margin: 0 32px;

  &:hover .hover-box {
    transform: translateY(-25px);
    box-shadow: 0px 8px 20px rgba(69, 91, 229, 0.12);
  }

  .hover-box {
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(69, 91, 229, 0.12);
    border-radius: 16px;
    height: 380px;
    padding: 40px;
    box-sizing: border-box;
    margin: 0;
    margin-right: 40px;
    position: relative;
    transition: all .5s;
    padding: 40px 0;

    &:last-child {
      margin-right: 0;
    }

    .title {
      @include FlexCenter();
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 40px;

      &-left {
        @include FlexCenter();

        &-new::after {
          content: "";
          display: inline-block;
          position: relative;
          top: -24px;
          left: -8px;
          background-image: url('../../../../img/newInfo/new_icon.svg');
          background-repeat: no-repeat;
          width: 54px;
          height: 32px;
        }
      }

      &-text {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #000000;
        margin-left: 4px;
      }

      &-right {
        @include FlexCenter();
        font-size: 20px;
        line-height: 24px;
        color: #1966ff;
      }

      &-icon {
        width: 37.65px;
        height: 40px;
      }
    }

    .content {
      width: 100%;
      box-sizing: border-box;
      // height: 226px;
      padding: 12px 0 20px 0;
      background: url("../../../../img/newInfo/info_bg_logo.png") no-repeat
        133px 22px;
      background-size: 174.55px 200px;
    }

    .back_hidden {
      background: none;
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    position: relative;
    width: 310px;
    min-height: 281px;
    margin: 0 13px;
  
    &:hover .hover-box {
      transform: translateY(-27px);
      box-shadow: 0px 8px 20px rgba(69, 91, 229, 0.12);
    }
  
    .hover-box {
      background: #ffffff;
      box-shadow: 0px 8px 20px rgba(69, 91, 229, 0.12);
      border-radius: 16px;
      height: 254px;
      padding: 26px 0;
      box-sizing: border-box;
      margin: 0;
      margin-right: 40px;
      position: relative;
      transition: all .5s;
  
      &:last-child {
        margin-right: 0;
      }
  
      .title {
        @include FlexCenter();
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 26px;
  
        &-left {
          @include FlexCenter();

          &-new::after {
            transform: scale(.8);
            top: -15px;
            left: -14px;
          }
        }
  
        &-text {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          margin-left: 4px;
        }
  
        &-right {
          @include FlexCenter();
          font-size: 14px;
          line-height: 17px;
          color: #1966ff;
        }
  
        &-icon {
          width: 25px;
          height: 27px;
        }
      }
  
      .content {
        width: 100%;
        box-sizing: border-box;
        // height: 226px;
        padding: 0;
        background: url("../../../../img/newInfo/info_bg_logo.png") no-repeat
          133px 22px;
        background-size: 174.55px 200px;
      }
  
      .back_hidden {
        background: none;
      }
    }
  }
}