.wrap {
  padding-bottom: 40px;
}

.list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 34px;
}

.listItem {
  margin-right: 24px;
  cursor: pointer;
  width: 450px;
  height: 134px;
}

@media (max-width: 1636px) {
  .listItem {
    width: 300px;
    height: 89px;
    margin-right: 16px;
  }
}