@import "~statics/styles/mixins.scss";

.recruitLiveDetail {
  background-image: url("https://lf3-static.bytednsdoc.com/obj/eden-cn/uvpahylwvauhojylt_lm_tvjl/ljhwZthlaukjlkulzlp/pc_v2/recruit_detail_bg.png");
  background-size: cover;

  .content {
    @include FlexCenter;
    flex-direction: column;
    min-height: 600px;
  }

  .logo {
    align-self: flex-start;
    margin: 70px 0 70px calc(50vw - 730px);
    width: 336px;
    height: 36px;
  }

  .settleBtn {
    display: flex;
    justify-content: center;
    margin: 104px 0 120px;
  }
}

@media (max-width: 1636px) {
  .recruitLiveDetail {

    .logo {
      transform: scale(0.7);
      transform-origin: top left;
      margin: -10px 0 20px calc(50vw - 500px);
    }

    .settleBtn {
      margin: 20px 0 -20px;
    }
  }
}
