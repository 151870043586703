@import "~statics/styles/mixins.scss";

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 20px;
  height: 20px;
  margin: 0 0 4px 0;
}
// 入驻意向
.iconQuestion {
  background-image: url("./img/question.svg");
}
// 调研问卷
.iconSurvey {
  background-image: url("./img/survey.svg");
}
// 入驻秘籍
.iconCheckCheats {
  background-image: url("./img/lightning.svg");
}
// 联系商服
.iconService {
  background-image: url("./img/contact-operations.svg");
}
// 服务商指导
.iconServiceProvider {
  background-image: url("./img/guidance.svg");
}
// 第三方店铺验证
.iconVerifyPage {
  background-image: url("./img/verify.svg");
}

.tooltip {
  text-align: center;
  color: #fff;
}

.serviceProviderAuthorizeModal :global {
  .auxo-modal-content {
    max-height: 650px;
  }
  .auxo-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.box {
  position: fixed;
  bottom: 32px;
  right: 40px;
  z-index: 10;
  transition: all .3s;
}

.douji {
  position: relative;
  left: -2px;
  top: -10px;
  cursor: pointer;
  width: 80px;
  height: 112px;
  object-fit: cover;
}

.item-wrapper {
  @include FlexCenter();
  cursor: pointer;
  // border-bottom: 1px solid #1556d9;
  /* 16 是 item-wrapper 的 左右 merge 总和合 */
  width: 50px;
  // height: 60px;
  flex-direction: column;

  &:last-child {
    border-bottom: none;
  }

  &-last {
    border-bottom: none;
    width: 80px;
    margin: 0;
  }

  .title {
    font-family: "PingFang SC";
    font-style: normal;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
}

.hover-nav {
  width: 124px;
  padding: 4px 0;
  background: #1966ff;
  box-shadow: 0 4px 8px rgba(120, 171, 255, 0.5);
  border-radius: 4px;
  transition: all 0.3s;
  will-change: transition;
  width: 80px;
  background: linear-gradient(59deg, #1a66ff 23%, #4785ff 86%);
  border-radius: 8px;
  padding: 14px 0;



  .common-problem-wrapper {
    @include FlexCenter();
    overflow: hidden;
    width: 80px;
    flex-direction: column;
    margin: 0;

    &-title {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 4px;
      font-size: 12px;
    }

    .icon-triangle {
      background-image: url("../../statics/img/hover-nav/triangle.svg");
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      transform: rotate(180deg);

      &-close {
        transform: rotate(0deg);
      }
    }

    .drop-down {
      @include FlexCenter();
      height: 144px;
      width: 76px;
      padding: 8px 0;
      box-sizing: border-box;
      transition: all 0.3s;
      margin: 0;
      flex-direction: column;
      background: #0050f1;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06),
        inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(20px);
      border-radius: 4px;
      overflow: hidden;

      &-item {
        @include FlexCenter();
        width: 62px;
        height: 32px;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        color: #ffffff;

        &:active {
          font-weight: 600;
          background: #f8f9fa;
        }

        &:hover {
          background: #0f4bc2;
          box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
          backdrop-filter: blur(20px);
          border-radius: 4px;
          color: #ffffff;
        }
      }
    }

    .drop-down-close {
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
}

.center {
  @include FlexCenter();
  width: 80px;
}

.low-resolution-screen {
  transform: translateY(93%) !important;
}

.is-step-aside {
  position: fixed;
  right: 0;
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
}

:global {
  .summon-iframe-contrainer [data-popper-arrow] {
    display: none !important;
  }
}

.contactOperationModal :global {
  .auxo-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1636px) {
  $width: 64px;

  .icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 32px;
    margin: 0 0 4px 0;
    width: 20px;
    height: 20px;
  }

  .douji {
    width: 74px;
    left: -7px;
    height: auto;
  }

  .hover-nav {
    padding: 4px 0;
    background: #1966ff;
    box-shadow: 0 4px 8px rgba(120, 171, 255, 0.5);
    border-radius: 4px;
    transition: all 0.3s;
    will-change: transition;
    width: $width;
    background: linear-gradient(59deg, #1a66ff 23%, #4785ff 86%);
    border-radius: 8px;
    padding: 10px 0;
    box-sizing: border-box;

    .item-wrapper {
      @include FlexCenter();
      cursor: pointer;
      border-bottom: 1px solid #1556d9;
      /* 16 是 item-wrapper 的 左右 merge 总和合 */
      width: calc($width - 16px);
      flex-direction: column;
      border-radius: 0;
      height: 62px;
      margin: 0 8px;

      &:last-child {
        border-bottom: none;
      }

      &-last {
        border-bottom: none;
      }

      .title {
        font-family: "PingFang SC";
        font-style: normal;
        color: #ffffff;
        margin: 0px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .common-problem-wrapper {
      @include FlexCenter();
      overflow: hidden;
      width: $width;
      flex-direction: column;
      margin: 0;

      &-title {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 4px;
        font-size: 12px;
        font-weight: 400;
      }

      .icon-triangle {
        display: none;

        // &-close {
        // transform: rotate(0deg);
        // }
      }

      .drop-down {
        @include FlexCenter();
        height: 144px;
        width: calc($width - 4px);
        padding: 8px 0;
        box-sizing: border-box;
        transition: all 0.3s;
        margin: 0;
        flex-direction: column;
        background: #0050f1;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06),
          inset 0px 0px 10px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(20px);
        border-radius: 4px;
        overflow: hidden;

        &-item {
          @include FlexCenter();
          width: 78px;
          height: 32px;
          font-size: 12px;
          line-height: 13px;
          cursor: pointer;
          color: #ffffff;

          &:active {
            font-weight: 600;
            background: #f8f9fa;
          }

          &:hover {
            background: #0f4bc2;
            box-shadow: unset;
            backdrop-filter: unset;
            border-radius: 4px;
            color: #ffffff;
          }
        }
      }

      .drop-down-close {
        height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (max-height: 700px) {
  .box {
    bottom: 0;
    transform: scale(0.83) translate3d(0, 0, 0);
    transform-origin: bottom left;
  }

  .douji {
    top: -3px;
    height: auto;
  }

  .hover-nav {
    padding: 0;

    .common-problem-wrapper {
      .drop-down {
        height: 110px;
        padding: 0;

        &-item {
          height: 24px;
        }
      }

      .drop-down-close {
        height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (max-height: 800px) and ( min-width: 1636px) {
  .box {
    bottom: 10px;
  }
  
  .douji {
    top: -6px;
    height: auto;
  }

  .hover-nav {
    .common-problem-wrapper {
      .drop-down-close {
        height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}


/* 覆盖全局联系商服弹窗样式 （sdk 内部原因与窗口对不齐） */
:global {
  .summon-iframe-contrainer {
    position: fixed !important;
    right: 130px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}

@media (max-width: 1636px) {
  :global {
    .summon-iframe-contrainer {
      transform: translateY(-50%) scale(.7) !important;
      transform-origin: right;
    }
  }
}