@mixin InfoListPageTitleStyle {
  height: 28px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #12141A;
  margin: 40px 0 24px 20px;
}

@mixin InfoListPageTabsStyle {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #12141A;
}

$newInfoListActiveBg: #4657F6;

.page_wrapper {
  display: flex;
  justify-content: center;
  background:#ffffff;
  width: 100%;
  height: 100%;
  margin-top: -20px;
  margin-bottom: -48px;
}

.wrapper {
  display: flex;
  width: 1228px;

  .left {
    width: 895px;
    min-height: 940px;

    &_title {
      @include InfoListPageTitleStyle;
    }

    .tabs {
      width: 815px;
      margin-left: 20px;

      &_item_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      :global {
        .auxo-tabs-tab {
          margin: 0;
          margin-right: 33px;
          padding-bottom: 5px;
        }

        .auxo-tabs-ink-bar {
          background: $newInfoListActiveBg;
        }
      }

      &_item {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      &_label {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #12141A;

        &_active {
          @include InfoListPageTabsStyle;
          color: $newInfoListActiveBg;
        }

        &:hover {
          @include InfoListPageTabsStyle;
          color: $newInfoListActiveBg;
        }
      }
    }

    .pagination {
      box-sizing: border-box;
      padding: 40px 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .right {
    margin-left: 60px;

    &_title {
      @include InfoListPageTitleStyle;
      margin-bottom: 20px;
      margin-left: 0;
    }

    .hot_pic_wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      .hot_pic {
        width: 120px;
        height: 80px;
        border-radius: 4px;
        margin-bottom: 16px;
      }
    }
  }
}

.empty_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 280px;
  margin-bottom: 428px;

  .empty {
    width: 120px;

    .img {
      width: 120px;
      height: 84px;
    }

    .text {
      width: 120px;
      height: 18px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #55585C;
      margin-top: 11.65px;
    }
  }
}


