.part-two {
  margin-top: 80px;
  .top-info {
    position: relative;
  }
  .title {
    font-family: PingFang SC;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    color: #000;
  }
  .right-btn {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    position: absolute;
    right: 0;
    color: #898b8f;
    top: 12px;
    cursor: pointer;
    a {
      color: #898b8f !important;
    }
  }
  .interest-main {
    display: flex;
    margin-top: 55px;
    .main-card {
        flex: 1;
        padding: 24px;
        background: linear-gradient(
            to bottom,
            rgba(255, 216, 170, 0.2),
            rgba(255, 229, 199, 0.1),
            rgba(255, 216, 170, 0.2)
          );
          &:first-child {
            margin-right: 32px;
          }
          border-width: 2px;
          border-radius: 12px;
          border-style: solid;
          box-shadow: 0px 4px 12px 0px rgba(255, 255, 255, 0.25) inset;
          border-color: rgba(255, 229, 199, 0.46);
          
    }
    .card-icon {
      width: 50px;
    }
    .card-title {
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      text-align: left;
      color: #000;
    }
    .card-desc {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin-top: 12px;
      .gray {
        color: #7F7F7F;
      }
      .yellow {
        color: #f5963b;
      }
    }
  }
  .interest-second {
    display: flex;
    margin-top: 40px;
    .second-card {
        flex: 1;
        padding: 24px;
        background: linear-gradient(
            to bottom,
            rgba(249, 251, 255, 1),
            rgba(251, 252, 255, 1)
          );
        &:not(:first-child){
            margin-left: 32px;
        }
        .gray {
          color: #7F7F7F;
        }
    }
    .card-icon {
        width: 50px;
      }
      .card-title {
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
        color: #000;
      }
      .card-desc {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 12px;
      }
  }
  .detailLink {
    font-size: 14px;
  }
}
