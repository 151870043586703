.wrapper {
  position: relative;
  width: 178px;
  height: 46px;

  background: #F0F6FF;
  border: 1px solid #1966FF;
  border-radius: 8px;

  cursor: pointer;
  overflow: hidden;

  .textWrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 178px;
    height: 46px;

    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #262626;

    transition: all .3s;

    .icon {
      width: 30px;
      height: 30px;
    }
  
    .arrow {
      width: 6.76px;
      height: 12px;
      margin-left: 8px;
    }

    &-out-of-focus {
      transform: translateX(-100%);
    }
  }
  
  .success {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: all .3s;

    &-icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 4px;
    }

    &-active {
      transform: translateX(0);
    }
  }
}