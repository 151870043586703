.top-banner {
    padding: 20px 220px;
    font-family: PingFang SC;
    @media (max-width: 1636px) {
      padding: 20px 46px;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('https://lf3-static.bytednsdoc.com/obj/eden-cn/lsaalz/ljhwZthlaukjlkulzlp/offical_index/brand-invite-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 605px;
    .block-content {
      min-width: 1480px;
      .logo {
        width: 95px;
        height: 100%;
        margin-right: 24px;
      }
    }
    .bannerimg {
      // position: absolute;
    }
    .banner-title {
      font-size: 68px;
      font-weight: 600;
      line-height: 95.2px;
      text-align: left;
      color: #000000;
      margin-top: 94px;
    }
    .banner-desc-row {
      display: flex;
      align-items: center;
      margin-top: 18px;
      .banner_desc {
        margin-right: 12px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        .banner-tip {
          font-size: 21px;
          font-weight: 500;
          line-height: 29.4px;
          text-align: left;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }