.restricted {
  font-size: 14px;
  line-height: 20px;

  &-wrapper {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-img {
    width: 156px;
    height: 86px;
    margin-bottom: 16px;
  }

  &-text {
    width: 480px;
    margin-bottom: 28px;
    text-align: center;
    font-size: 14px;
    color: #12141a;
  }

  &-btn {
    width: 192px;
    height: 48px;
    margin-bottom: 16px;
    border-radius: 24px;
  }

  &-link {
    font-size: 14px;
    color: #1966ff;
  }
}
