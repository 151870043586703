.bg {
  background: #f0f6ff;
  background-size: cover;
  height: calc(926px - 147px);
  display: flex;
  justify-content: center;
  padding-top: 60px;
  position: relative;
  overflow: hidden;

  &-logo {
    bottom: 75px;
    left: 56.3%;
    position: absolute;
  }

  .container {
    height: 100%;
    color: #fff;
    text-align: center;

    .title {
      font-weight: 500;
      font-size: 36px;
      line-height: 50px;
      text-align: center;
      color: #000000;
    }

    .subtitle {
      margin-top: 16px;
      margin-bottom: 80px;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .card {
    display: flex;

    & * {
      transition: all 0.3s;
    }

    &-item {
      border-radius: 8px;
      box-shadow: 0 6px 32px rgba(37, 41, 49, 0.06);
      margin: 0 9px;
      position: relative;
      overflow: hidden;
      height: 438px;

      &-not-active {
        width: 180px;
      }

      &-active {
        width: 868px;
      }

      &-bg-logo {
        position: absolute;
        z-index: 4;

        &-not-active {
          width: 300px;
          height: 300px;
          left: -10px;
          top: 148px;
          opacity: 0.4;
        }

        &-active {
          opacity: 0.05;
          width: 380px;
          height: 380px;
          left: 236.35px;
          top: -45px;
          transform: rotate(15deg);
        }
      }

      &-mask {
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 6;
        bottom: 0;
        opacity: 0.8;

        &-dispear {
          opacity: 0;
        }
      }

      &-logo {
        display: flex;
        margin-top: 24px;
        transition: display 0.5s ease-in-out;

        & > img {
          // width: 98px;
          height: 28px;
        }

        &-not-active {
          justify-content: center;
        }

        &-active {
          margin-left: 40px;
          justify-content: flex-start;
        }
      }

      &-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        text-align: left;
        padding-left: 40px;
        margin-top: 16px;
        white-space: nowrap;
        text-overflow: hidden;

        &-not-active {
          opacity: 0.5;
        }

        &-active {
          color: #252931;
        }
      }

      &-text {
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        padding-left: 40px;
        margin-top: 8px;
        white-space: nowrap;
        text-overflow: hidden;

        &-not-active {
          opacity: 0.3;
        }

        &-active {
          color: #898b8f;
        }
      }
    }
  }
}

.website {
  position: absolute;
  z-index: 5;
  left: 40px;
  top: 163px;
  width: 840px;

  &-top {
    height: 14px;
    background-color: #f0f0f0;
    border-radius: 8px 0;
    padding-left: 6px;
    display: flex;
    align-items: center;

    &-icon {
      width: 4px;
      height: 4px;
      margin-right: 4px;
      border-radius: 50%;
    }
  }

  &-not-active {
    opacity: 0.15;
  }

  &-img {
    width: 840px;
  }
}

.settle-btn {
  margin-top: 80px;
}

@media (max-width: 1636px) {
  .bg {
    background: #f0f6ff;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding-top: 53px;
    position: relative;
    overflow: hidden;
    height: calc(618px - 94px);

    &-logo {
      bottom: 75px;
      left: 56.3%;
      position: absolute;
    }

    .container {
      height: 100%;
      color: #fff;
      text-align: center;

      .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #000000;
      }

      .subtitle {
        margin-top: 10px;
        margin-bottom: 60px;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .card {
      display: flex;

      & * {
        transition: all 0.3s;
      }

      &-item {
        border-radius: 8px;
        box-shadow: 0 6px 32px rgba(37, 41, 49, 0.06);
        margin: 0 9px;
        position: relative;
        overflow: hidden;
        height: 292px;

        &-not-active {
          width: 180px;
        }

        &-active {
          width: 578.67px;
        }

        &-bg-logo {
          position: absolute;
          z-index: 4;

          &-not-active {
            width: 300px;
            height: 300px;
            left: -10px;
            top: 148px;
            opacity: 0.4;
          }

          &-active {
            opacity: 0.05;
            width: 380px;
            height: 380px;
            left: 236.35px;
            top: -45px;
            transform: rotate(15deg);
          }
        }

        &-mask {
          height: 100%;
          position: absolute;
          right: 0;
          z-index: 6;
          bottom: 0;
          opacity: 0.8;

          &-dispear {
            opacity: 0;
          }
        }

        &-logo {
          display: flex;
          margin-top: 24px;
          transition: display 0.5s ease-in-out;

          & > img {
            // width: 98px;
            // height: 28px;
            height: 16px;
          }

          &-not-active {
            justify-content: center;
          }

          &-active {
            margin-left: 24px;
            justify-content: flex-start;
          }
        }

        &-title {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: left;
          padding-left: 24px;
          margin-top: 10px;
          white-space: nowrap;
          text-overflow: hidden;

          &-not-active {
            opacity: 0.5;
          }

          &-active {
            color: #252931;
          }
        }

        &-text {
          font-size: 14px;
          line-height: 19px;
          text-align: left;
          padding-left: 24px;
          margin-top: 4px;
          white-space: nowrap;
          text-overflow: hidden;

          &-not-active {
            opacity: 0.3;
          }

          &-active {
            color: #898b8f;
          }
        }
      }
    }
  }

  .website {
    position: absolute;
    z-index: 5;
    // left: 60px;
    left: 26px;
    // top: 163px;
    top: 114px;
    width: 560px;

    &-top {
      height: 14px;
      background-color: #f0f0f0;
      border-radius: 8px 0;
      padding-left: 6px;
      display: flex;
      align-items: center;

      &-icon {
        width: 4px;
        height: 4px;
        margin-right: 4px;
        border-radius: 50%;
      }
    }

    &-not-active {
      opacity: 0.15;
    }

    &-img {
      // height: 280px;
      width: 560px;
    }
  }

  .settle-btn {
    margin-top: 80px;
  }
}


.settle-btn {
  margin-top: 80px;
}
