.wrapper {
  display: flex;
  height: 112px;
  width: 835px;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(69, 91, 229, 0.1);
  }

  .left {
    display: flex;
    justify-content: flex-start;
    width: 108px;
    box-sizing: border-box;
    padding: 20px 0;

    .pic {
      border-radius: 4px;
      width: 108px;
      height: 72px;
    }
  }

  .right {
    margin-left: 16px;

    .top {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .new_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        background: #F23755;
        border-radius: 2px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #FFFFFF;
        margin-right: 4px;
      }

      .title {
        width: 671px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        height: 22px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #12141A;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .classify {
        display: flex;
        align-items: center;

        .classify_icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        .classify_text {
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
          color: #55585C;
          margin-right: 12px;
        }
      }

      .date {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        color: #999999;
      }
    }
  }
}

.wire {
  width: 815px;
  height: 1px;
  background: #F2F2F2;
  transform: matrix(1, 0, 0, -1, 0, 0) translateX(20px);
}
