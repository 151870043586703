@import "~statics/styles/mixins.scss";

.wrap {
  display: flex;
  justify-content: center;
  width: 1480px;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-top: -60px;
  z-index: 10;
  // &:after {
  //   content: ' ';
  //   position: absolute;
  //   top: -80px;
  //   width: 100vw;
  //   height: 100px;
  //   z-index: -1;
  //   background: linear-gradient(180deg, rgba(246, 251, 255, 0) 0%, #FDFEFF 70.43%) no-repeat center top/ 100% 100px;
  // }
}

.paddingTop {
  padding-top: 142px;
}

.list {
  display: flex;
  justify-content: space-between;
}

.tips {
  width: 520px;
  height: 42px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #898B8F;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url('./assets/tips.svg') no-repeat center;
    background-size: 14px;
    margin-right: 9px;
  }
}

.settleButton {
  background-color: unset;
  padding: 0;
  padding-top: 34px;
}

@media (max-width: 1636px) {
  .list {
    width: 1480px;
    scale: 0.8;
  }
  .wrap {
    margin-top: -130px;
    // &:after {
    //   width: 100vw;
    //   top: -24px;
    // }
  }
  .paddingTop {
    padding-top: 84px;
  }
}
