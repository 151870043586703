.registerPage {
  padding: 24px 24px 0;
  text-align: center;

  .title {
    padding-bottom: 16px;
    color: #252931;
    font-size: 20px;
    font-weight: 600;
  }

  .subTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(25, 102, 255, 1);
    height: 30px;
    width: 100%;
    background-color: rgba(25, 102, 255, .1);
    border-radius: 4px;
    margin-bottom: 8px;

    img {
      margin-right: 8px;
    }
  }
  
  .downloadLink {
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
