@import '~statics/styles/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  top: -10px;
  margin-left: 30px;

  .img {
    width: 140px;
    height: 120px;
    margin-right: 16px;
    cursor: pointer;
    // margin-top: 11px;
  }

  .info {

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 1px;
      font-feature-settings: 'vert' on;
      color: #25292E;
      margin-bottom: 6px;
      // margin-top: 10px;
    }

    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
      font-feature-settings: 'vert' on;
      
      color: #25292E;

      margin-bottom: 22px;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 32px;
      background: #1966FF;
      border-radius: 4px;

      font-weight: 400;
      font-size: 16px;
      line-height: 11px;
      color: #FFFFFF;
      cursor: pointer;
      overflow: hidden;

      .download {
        display: flex;
        width: 128px;
        transform: translateX(-100%);
        transition: all .3s;

        &-success {
          transform: translateX(-300%);
        }

        &ing {
          transform: translateX(-200%);
        }

        &-error {
          transform: translateX(0);
        }

        &-icon {
          width: 30px;
          height: 30px;
        }

        &-flex {
          @include FlexCenter;
          width: 128px;
          height: 32px;
          flex-shrink: 0;
          transition: all .3s;

          &:hover {
            background: #4784FF;
          }
        }
      }
    }
  }
}

@media (max-width: 1636px) {

  .wrapper {
    margin-left: 10px;
    margin-top: 8px;
    flex-shrink: 0;

    .img {
      width: 107px;
      height: 93px;
      margin-right: 10px;
    }
    
    .info {

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
  
      .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 14px;
      }
  
      .btn {
        width: 80px;
        height: 21px;

        .download {
          svg {
            width: 16px !important;
            height: 16px !important;
          }

          &-icon {
            width: 30px;
            height: 30px;
          }

          &-flex {
            font-weight: 400;
            font-size: 12px;
            line-height: 8px;
          }
        }
      }
    }
  }
}