.page {
  padding-bottom: 100px;
}

.bannerWrapper {
  position: relative;
  height: 292px;
}

.block1 {
  position: absolute;
  width: 100%;
  top: -88px;
  overflow-y: visible;
}

.footerContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, .7);
  backdrop-filter: blur(6px);/** firefox不支持 */
  z-index: 2;

  .settleButton {
    margin: auto 0;
    width: 190px;
    height: 40px;
    background: #1966ff;
    border-radius: 4px;
    position: relative;
    font-size: 16px;
    display: inline-block;
  }

  .settleButtonImg {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 100px;
    top: 0;
    animation: straightLine 1.8s infinite ease;
    -webkit-animation: straightLine 1.8s infinite ease;
  }

  @keyframes straightLine {

    from {
      left: 0;
    }

    to {
      left: 190px;
    }
  }

  @-webkit-keyframes straightLine {

    from {
      left: 0;
    }

    to {
      left: 190px;
    }
  }
}
