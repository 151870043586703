.promotion {

  .iframe {
    width: 100vw;
    min-height: calc(100vh - 88px);
    border: none;
  }
 
  
  .settle {
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
}
