.settleGuideContainer {
  height: 440px;

  .settleGuideTitle {
    margin-bottom: 40px;
    color: rgba(0, 0, 0);
    font-weight: 500;
    padding-top: 80px;
    text-align: center;
    font-size: 40px;
    line-height: 50px;
  }

  .settleGuideTip {
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  }

  .buttonGroup {
    display: flex;
    justify-content: center;

    div {
      width: 450px;
      height: 140px;

      img {
        width: 100%;
      }
    }

    div:not(:last-child) {
      margin-right: 24px;
    }
  }
}

@media (max-width: 1636px) {

  .settleGuideContainer {
    height: 265px;

    .settleGuideTitle {
      margin-bottom: 10px;
      padding-top: 40px;
      font-size: 28px;
      line-height: 34px;
    }

    .settleGuideTip {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 22px;
    }

    .buttonGroup {

      div {
        width: 300px;
        height: 94px;
      }

      div:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}