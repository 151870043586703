.phoneAndVerify {

  .input {
    height: 40px;
    
    &Prefix {
      color: #12141A;
      line-height: 1;
      padding-right: 10px;
      margin-right: 8px;
      border-right: 1px solid #EEEFF0;
    }
  }

  .btn {
    width: 100%;
    height: 40px;
  }

  :global {
    .auxo-form-item {
      margin-bottom: 16px;
    }
  }
}
