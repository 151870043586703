.hotSpu {
  display: flex;

  .left {
    padding-left: 12px;
  }

  .right {
    margin-left: 24px;
  }

}
