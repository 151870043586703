.pickFormContainer {
  margin: 64px 46px 99px 60px;
  height: 100%;

  .pickFormHeader {

    img {
      height: 36px;
      width: 36px;
      vertical-align: middle;
    }

    div {
      display: inline-block;
      font-size: 28px;
      color: rgba(0, 0, 0);
      font-weight: 500;
      vertical-align: middle;
    }
  }

  .content {
    width: 609px;
    margin-left: 44px;

    & :global {

      .auxo-form-item {
        margin-bottom: 16px;
      }

      .auxo-form-item-label:not(.sp-null) > label {
        font-size: 20px;
        height: 58px;
      }

      .auxo-select:not(.auxo-select-customize-input) .auxo-select-selector {
        height: 58px;
      }

      .auxo-select-arrow {
        right: 20px;
      }

      .auxo-input {
        height: 58px;
        font-size: 20px;
      }

      .auxo-select-selection-item {
        line-height: 58px !important;
        font-size: 20px;
      }

      .auxo-select-selection-placeholder {
        font-size: 20px;
        line-height: 58px !important;
      }

      .auxo-select-selection-search-input {
        height: 58px;
      }

      .auxo-form-item-explain-error {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .tip {
    font-size: 20px;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .searchButton {
    text-align: right;

    .btn {
      width: 100%;
      height: 60px;
      border-radius: 8px;
      font-size: 24px;
    }
  }
}

:global {
  .auxo-select-item {
    // height: 58px;
    // font-size: 20px;
    // line-height: 58px;
  }
}

@media (max-width: 1636px) {

  .pickFormContainer {
    margin: 40px 34px 62px 40px;
  
    .pickFormHeader {
  
      img {
        height: 24px;
        width: 24px;
      }
  
      div {
        font-size: 18px;
      }
    }
  
    .content {
      width: 93%;
      margin-left: 16px;
  
      & :global {
  
        .auxo-form-item {
          margin-bottom: 8px;
        }

        .auxo-form-item-label:not(.sp-null) > label {
          font-size: 14px;
          height: 38px;
        }
  
        .auxo-input {
          height: 38px;
          font-size: 14px;
        }
        
        .auxo-select:not(.auxo-select-customize-input) .auxo-select-selector {
          height: 38px;
        }
  
        .auxo-select-arrow {
          right: 8px;
        }
  
        .auxo-select-selection-item {
          line-height: 38px !important;
          font-size: 14px;
        }
  
        .auxo-select-selection-placeholder {
          font-size: 14px;
          line-height: 38px !important;
        }
  
        .auxo-select-selection-search-input {
          height: 38px;
        }
        
        .auxo-form-item-explain-error {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  
    .tip {
      font-size: 14px;
      margin-bottom: 14px;
      margin-top: 16px;
    }
  
    .searchButton {
  
      .btn {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        font-size: 16px;
      }
    }
  }
  :global {
    .auxo-select-item {
      height: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
