@import '../../statics/styles/mixins.scss';

html {
  scroll-behavior: smooth;
}

.home.v2 {
  .block1wrap {
    height: 478px
  }
}

.block1wrap {
  position: relative;
  height: 662px;
}

.block1 {
  position: absolute;
  width: 100%;
  // top: -60px;
  top: -88px;
  overflow-y: visible;
}

.home-placeholder {
  @extend .block1;
  position: relative;

  &-one {
    height: 620px;
    background-color: #d4e1f7;
    position: relative;
    top: -60px;
  }

  &-two {
    height: 487px;
    width: 100%;
    background: linear-gradient(0deg, #F2F6FE 0%, rgba(242, 246, 254, 0) 60.32%);
  }
}

.content {
  position: relative;
  background: #f0f6ff;
}

.hide {
  display: none;
}

.settle-btn {
  padding-bottom: 16px;
  box-sizing: unset;
}

@media (max-width: 1636px) {
  .block1wrap {
    position: relative;
    height: 494px;
  }

  .block1 {
    position: absolute;
    width: 100%;
    top: -66px;
    overflow-y: visible;
  }

  .home-placeholder {
    @extend .block1;
    position: relative;

    &-one {
      height: 620px;
      background-color: #d4e1f7;
      position: relative;
      top: -60px;
    }

    &-two {
      height: 487px;
      width: 100%;
      background: linear-gradient(0deg, #F2F6FE 0%, rgba(242, 246, 254, 0) 60.32%);
    }
  }
}

