.indexBar {
  position: relative;
}

.sidebar {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 3;
  overflow: visible;
  font-size: 10px;
  user-select: none;
  touch-action: none;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  width: 16px;
}

.sidebarItem {
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 2px;
  margin-bottom: 2px;

  &:hover {
    background: rgba(25, 102, 255, 0.1);

    // opacity: 0.1;
  }
  &Active {
    background: rgba(25, 102, 255, 0.2);
    color: #1966ff;

    // opacity: 0.2;
  }
}

.title {
  height: 24px;
  line-height: 24px;
  background: #edeeef;
  font-size: 14px;
  color: #000000;
  padding-left: 20px;
}
