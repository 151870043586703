.login {

  &-box {
    width: 352px;
    height: 480px;

    :global {

      .ecomLoginBox {
        height: 480px;
        box-shadow: none;

        .account-center-header-title {
          font-size: 24px;
          font-weight: 500;
          color: #252931;
          font-family: 'PingFang SC', sans-serif;
        }
      }
      .account-center-input-row {
        position: relative;
      }

      .account-center-code-captcha {
        padding-top: 9px;
        padding-right: 4px;

        height: 40px !important;
        position: absolute !important;
        top: -3px !important;
        right: 3px !important;
      }

      #oauth-login {
        margin-top: -20px;
      }

      .account-center-action-button {
        &:hover {
          transition: all .3s;
          background-color: #4784FF !important;
        }
      }

      .switch-type-switch {
        display: none !important;
      }

      a[href="/login"] {
        background-color: transparent;
        color: #1966ff;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        transition: color .3s;
      }

      .account-center-header-common {
        display: flex !important;
        justify-content: center;
      }

      .account-center-sdk-form {
        margin-top: 24px !important;
      }
    }
  }
}

@keyframes fadeIn {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
};

@keyframes fadeOut {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.login-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 1001;

  :global {

    #oauth-login {
      margin-top: -28px;
    }

  }

  &-show {
    position: fixed;
    z-index: 1001;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    animation: fadeIn .3s ease-in-out 0s 1 alternate forwards;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(4px);
  }

  &-loginWap {
    position: relative;
    background: #fff;
    box-shadow: 0 10px 51px rgba(53, 76, 166, .11);
    border-radius: 13px;
    height: 452px;
    overflow: hidden;
  }
  &-tipsWap {
    position: relative;
    background: #fff;
    box-shadow: 0 10px 51px rgba(53, 76, 166, .11);
    border-radius: 13px;
    height: 402px;
    width: 500px;
    overflow: hidden;
  }

  &-close {
    position: absolute;
    right: 32px;
    top: 48px;
    width: 14px;
    height: 14px;
    z-index: 1000;
    background: url("../../statics/img/login/close.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}

.login-popup-sub-title {
  height: 480px !important;

  :global {

    .ecomLoginBox {
      height: 480px;
    }
    
    .account-center-header {
      height: 60px;
    }

    .account-center-header-sub-title {
      top: 40px !important;
      height: 30px !important;
      line-height: 28px !important;
      background-color: rgba(25, 102, 255, .1);
      background-image: url('../../statics/img/login/sub-title-icon.svg');
      background-repeat: no-repeat;
      color: #1966ff !important;
      border-radius: 4px;
      margin-bottom: 8px;
      font-size: 14px !important;
      padding-left: 14px;
    }

  }

}

