@keyframes straightLine {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.wrap {
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #fff;
  z-index: 9;
  
  .btn {
    width: 194px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #1966FF;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 13px;
    color: #1966FF;
    margin: 0 12px;

    &:hover {
      border: 1px solid #4784FF;
      color: #4784FF;
    }
  }

  .active {
    position: relative;
    overflow: hidden;
    color: #fff;
    background: #1966FF;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px ;
    width: 310px;

    &:hover {
      background: #4784FF;
      color: #fff;
    }

    .icon {
      width: 10px;
      height: 17px;
      margin-left: 6px;
      transform: translateY(-2px);
    }

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 100px;
      top: 0;
      animation: straightLine 2.5s infinite ease;
      -webkit-animation: straightLine 2.5s infinite ease;
    }
  }
}