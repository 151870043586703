.wrap {
  background-color: #F7FBFF;
  overflow: hidden;
  padding-bottom: 16px;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 354px);
  grid-template-rows: repeat(auto-fit, 270px);
  
  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 34px;
  }
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  width: 1358px;
  height: 50px;
  margin-top: 40px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  &::before {
    content: "";
    flex: 1;
  }

  .btn {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #1966FF;
    cursor: pointer;

    &::after {
      content: "";
      width: 8.74px;
      height: 14.57px;
      background: url('./img/arrow.svg') no-repeat center;
      background-size: 8.74px 14.57px;
      margin-left: 7px;
    }
  }
}

.hide {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 1636px) {
  .list {
    display: grid;
    grid-template-columns: repeat(4, 236px);
    grid-template-rows: repeat(auto-fit, 180px);

    &-wrap {
      margin-top: 24px;
    }
  }

  .title {
    margin-top: 26px;
    width: 915px;

    .btn {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;

      &::after {
        content: "";
        width: 6px;
        height: 10px;
        background: url('./img/arrow.svg') no-repeat center;
        background-size: 6px 10px;
        margin-left: 7px;
      }
    }
  }
}