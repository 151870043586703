@import "../../statics/styles/mixins.scss";
$menuHoverColor: #1966ff;

.menuHoverStyle {
  color: $menuHoverColor;
  font-weight: 500;
}

.wrapper {
  min-height: calc(100vh - 236px);
  background-color: #f3f4f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 48px;
  background-color: #fff;
}

.container {
  display: flex;
  justify-content: center;
  padding: 24px 32px;
  // width: 1160px;
  width: 1430px;
  border-radius: 4px;
  min-height: 200px;
  padding-left: 0;
  padding-right: 0;
}

.menu {
  position: relative;
  min-width: 95px;
  border-right: 1px solid #eeeff0;
  
  &-item {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;

    a {
      color: inherit;
    }

    &-not-active {
      color: #565960;
    }

    &:hover {
      @extend .menuHoverStyle;
      cursor: pointer;
    }

    &-active {
      @extend .menuHoverStyle;
      position: relative;

      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        left: -12px;
        background-color: $menuHoverColor;
        border-radius: 4px;
      }
    }
  }
}

.main {
  min-height: calc(100vh - 300px);
  padding-left: 39px;
  position: relative;
  padding-right: 32px;
  padding-right: 20px;
  width: 870px;
  background-color: #fff;

  &-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 60px;
    z-index: 10;
    width: 100%;
    height: 76px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 1px 0px #edeef0;
    top: 86px;

    .category {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .total {
      font-size: 12px;
      line-height: 16px;
      color: #898b8f;
      margin-left: 12px;
    }

    .new-total {
      @include FlexCenter();
      height: 26px;
      padding: 0px 4px;
      background: #ebf3ff;
      border-radius: 4px;
      font-size: 16px;

      line-height: 16px;
      color: #1966ff;
      margin-left: 20px;
    }
  }

  .content {
    list-style: none;
    padding: 0;
  }

  .content-item {
    margin-top: 24px;
    height: min-content;

    &-ques,
    &-ques-single {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #252931;
    }

    &-new-ques {
      > a {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #252931;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    &-ques:hover {
      color: $menuHoverColor;
      cursor: pointer;
    }

    &-ans {
      margin-top: 12px;
    }
  }

  .routeLink {
    font-size: 12px;
    line-height: 16px;
    color: #898b8f;
    margin: 0 8px 24px;
    display: inline-block;
  }
  
  .back {
    width: 20px;
    height: 40px;
    margin-right: 16px;
    cursor: pointer;
  }

  .cateLink {
    margin-left: 0;

    a {
      color: inherit;
    }

    &:hover {
      cursor: pointer;
      color: $menuHoverColor;
    }
  }
}

:global {
  html {
    scroll-behavior: smooth;
  }
}

.sub-menu-popup {
  background: #ffffff;
}

.menu-wrapper {
  position: sticky;
  transition: all 0.3s;
  top: 88px;

  &-menu {
    width: 287px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    -ms-overflow-style: none;

    &-selected-menu {
      color: #1966ff;
      background: rgba(25, 102, 255, 0.05);
      border-radius: 0px;
      font-weight: 500;

      .menu-item {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        //font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #85878a;
        background: #ffffff !important;
        width: 100%;

        &:active {
          color: #1966ff;
        }

        &:hover {
          color: #1966ff;
        }
      }

      .item-active {
        color: #1966ff;
        font-weight: 500;
        background: #ffffff !important;
      }
    }

    :global {
      .auxo-menu-item-only-child {
        &:after {
          display: none;
        }
      }
    }
  }

  :global {
    .auxo-menu-submenu-inline {
      .auxo-menu-inline {
        background: #ffffff;
      }
    }
  }
}

:global {
  .k-render__content * {
    font-size: 14px !important;
    white-space: pre-wrap;
    line-height: 26px;
    // padding: 0 !important;
  }

  .k-render__content *:not(a) {
    color: #565960 !important;
  }

  .k-render__content .innerdocbody.adit-container .heading-h2 span,
  .k-render__content .adit-outer-container .adit-container .heading-h2 span,
  .k-render__content .innerdocbody.adit-container .heading-h3 span,
  .k-render__content .adit-outer-container .adit-container .heading-h3 span {
    // font-weight: normal !important;
    // font-size: 14px !important;
  }
}

.settle-button {
  padding: 40px 0;
}


@media (max-width: 1636px) {
  .main {
    width: 680px;

    &-header {
      top: 56px;
    }
  }

  .container {
    width: auto;
  }

  .menu-wrapper {

    &-menu {
      width: 214px;
    } 
    &-new {
      top: 56px;
    }
  }

  .hot {
    top: 56px;
  }
}