.appointmentProcess {
  padding: 24px 24px 0 24px;
  background-image: url(./bg.png);
  background-size: contain;
  background-repeat: no-repeat;

  .title {
    padding-bottom: 24px;
    text-align: center;
    color: #252931;
    font-size: 20px;
    font-weight: 600;
  }
}
