.content {
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 480px;
  height: 496px;
  transform: translate(-50%,-50%);
  border-radius: 8px;
  transition: all 1s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
  
  .close {
    position: absolute;
    right: 31px;
    top: 42px;
    width: 20px;
    height: 20px;
    background: url('./assets/close.svg') no-repeat center;
    background-size: 20px;
  }

  .btn {
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);

    width: 288px;
    height: 40px;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }

}

.hide {
  opacity: 0;
}