.cateTip {
  margin-top: 16px;

  .text {
    line-height: 20px;
    font-size: 14px;
    color: #55585c;
  }

  .highlight {
    color: #ff7307;
  }

  .rules {
    color: #1966ff;
  }
}

.zeroText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ff8413;

  .urlLink {
    margin-left: 3px;
    color: #1966ff;
  }
}
