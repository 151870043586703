.hotSpuItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: flex-start;
    width: 98px;
  }
  
  .serialNumber {
    margin-right: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #1966FF;
    font-weight: 500;
  }
  
  .name {
    flex: 0 0 70px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .content {
    width: 268px;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    opacity: 0.8;
  }
}
