@mixin FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

:global {
  .auxo-tabs-nav:before {
    display: none;
  }
  .auxo-tabs-tab-btn:focus, .auxo-tabs-tab-remove:focus, .auxo-tabs-tab-btn:active, .auxo-tabs-tab-remove:active {
    color: unset;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 1160px;
  // height: 148px;
  padding: 0 32px;
  box-sizing: border-box;
  margin: 0 auto;
  order: -1;
  transition: all .3s;
  background: #FFFFFF;
  border-radius: 4px;
  filter: drop-shadow(0px 6px 32px rgba(37, 41, 49, 0.06));

  &-hidden {
    opacity: 0;
    display: none;
  }

  .left {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;

    .title {
      @include FlexCenter;
      margin-top: 24px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #000000;

      .edit {
        @include FlexCenter;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1966FF;
        margin-left: 16px;
        cursor: pointer;

        &:before {
          content: '';
          display: inline-block;
          background: url(../../../img/edit_icon.png) no-repeat;
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }

    .shopType {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 12px 0 0 0;
      padding: 0;

      &-item {
        list-style: none;
        margin-right: 32px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #55585C;

        &-key {
          margin-right: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #85878A;
        }
      }
    }
  }

  .right {
    @include FlexCenter;
    justify-content: unset;

    .btn {
      @include FlexCenter;
      width: 176px;
      height: 48px;
      background: #1966FF;
      border-radius: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
