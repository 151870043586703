.bannerWrap {
  height: 660px;

  > div {
    position: relative;
    top: -88px;
  }
}

.title {
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopExample {
  padding-top: 0;
  overflow: hidden;
  height: 640px;

  .shopExampleTitle {
    @extend .title;
  }

  .shopExampleContainer {
    padding-top: 42px;
  }
}

.bannerNav {
  bottom: 142px;
}

.diff {
  margin-top: 0;
}

.descHide {
  margin-top: 40px;
}

@media (max-width: 1636px) {
  .bannerNav {
    bottom: 80px;
  }
  
  .bannerWrap {
    position: relative;
    height: 488px;

    >div {
      top: -56px;
    }
  }
  
  .title {
    margin-top: 26px;
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
  }
  
  .diff, .descHide {
    margin-top: 26px;
  }

  .shopExample {
    height: 470px;

    .shopExampleContainer {
      padding-top: 20px;
    }
  }
}