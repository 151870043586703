.recruitLive {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: 540px;

  ul {
    margin-bottom: 0;
  }

  .playerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px 6px 0 0;
    padding: 0 24px;
    height: 70px;
    line-height: 70px;
    background-color: #202124;

    :global {
      .auxo-statistic-content {
        margin-left: 4px;
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .countdown {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #fff;
  }

  .bookBtn {
    border-radius: 26px;
  }

  .btnGroup {
    margin-left: 16px;
  }

  :global {

    .liveShareContainer {
      display: none;
    }

    .bytelive-PlayerComponent-countdownBox {
      display: none;
    }

    .bytelive-TopNotice-notice-wrapper-inner {
      display: none;
    }

    .bytelive-Player-floatingWrapper {
      display: none;
    }
  }
}

:global {
  .player {
    width: 960px;
    height: 540px;
    min-width: 640px;
    /*播放器最小宽度建议为640px*/
    max-width: 1280px;
    min-height: 370px;
    max-height: 775px;
  }

  .menu-top {
    width: 100%;
    display: flex;
  }
}


@media (max-width: 1636px) {

  .recruitLive {
    min-height: 370px;
    .playerHeader {
      height: 48px;
      line-height: 48px;
      padding: 0 16px;
  
      :global {
        .auxo-statistic-content {
          font-size: 16px;
        }
      }
    }
  
    .countdown {
      font-size: 14px;
    }
  }

  :global {
    .player {
      width: 640px;
      height: 370px;
    }
  }
}
