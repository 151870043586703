$borderStyle: 1px solid #eeeff0;

.blockWrap {
  margin-top: 7px;
  padding: 32px 0;

  &:last-of-type {
    border: none;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &-no-border {
    border: none;
  }
}

.title {
  font-size: 20px;
  line-height: 28px;

  &-padding {
    padding-top: 20px;
  }

  &-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  
    width: 100%;
    background: #fff;

    .bottom {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 57px;
      background-color: #fff;

      &-tabs {

        & :global {

          .auxo-anchor {
            display: flex;
          }

          .auxo-anchor-ink {
            display: none;
          }

          .auxo-anchor-link-title-active {
            color: #252931;
          }
        }

        .tab {
          font-size: 14px;
          line-height: 20px;
          color: #565960;

          &-active {
            font-weight: 500;
            color: #252931;
          }
        }
      }

      &-btn {
        width: 180px;
        height: 40px;
        border-radius: 200px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }

  }
  
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .subtitle {
      font-size: 16px;
      line-height: 16px;
      color: #000;
      opacity: .6;
      margin-left: 12px;
    }
  }
}



.title2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #85878a;
  margin: 21px 0 10px;

  .explain {
    font-size: 12px;
    padding-left: 12px;
  }
}

.material-block { // 这的样式跨境页面在用
  display: flex;
  justify-content: space-between;

  &-column {
    flex-direction: column;
  }

  &-item {
    padding: 16px 24px;
    border: $borderStyle;
    border-radius: 4px;
    margin-right: 20px;

    &:last-of-type {
      margin: 0;
    }

    &-column {
      margin-right: 0;
      margin-bottom: 20px;

      &:last-of-type {
        margin: none;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 22px;
      color: #12141a;
      font-weight: 500x;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .lookExample {
      font-size: 14px;
      line-height: 20px;
      color: #1966ff;
      cursor: pointer;
    }

    &-desc {
      font-size: 14px;
      line-height: 20px;
      color: #85878a;

      .authInfoLine2,
      .authInfoLine3 {
        position: relative;
        padding-left: 20px;
        margin-top: 8px;
      }

      .authInfoLine3 {
        margin-left: 20px;
      }

      .lineNum {
        position: absolute;
        left: 0;
        top: 0;
        height: 20px;
        line-height: 20px;
      }
    }
  }
}


.table {
  border-radius: 4px;

  thead {
    background: #f9f9fa;
    box-shadow: 0 1px 0 #edeef0;
    font-size: 14px;
    line-height: 20px;

    th {
      height: 52px;
      padding-left: 16px;
      color: #85878a;
      position: relative;
      font-weight: 500;
      color: #363c3d;
    }
  }

  .thWidth1 {
    width: 248px;
  }

  .thWidth2 {
    width: 842px;
  }
  border: 1px solid #eeeff0;
  //box-sizing: border-box;
  border-radius: 4px;

  tbody {

    th {
      border-right: 1px solid #eeeff0;
    }
    //tr td{
    //  box-shadow: 0px 1px 0px #EDEEF0;
    //}

    td {
      font-weight: 500;
      font-size: 14px;
      border-right: 1px solid #eeeff0;
      box-shadow: 0 1px 0 #edeef0;
      height: 52px;
      padding: 16px;
    }

    .del {
      color: #1966ff;
      cursor: pointer;

      &:after {
        position: absolute;
        border-right: 1px solid #eeeff0;
        content: "";
        top: -19px;
        left: -1px;
        height: 20px;
      }
    }

    .lookExample {
      font-size: 14px;
      line-height: 20px;
      color: #1966ff;
      cursor: pointer;
      display: inline-block;
      margin-left: 8px;
    }

    .tdDesc {
      font-weight: normal;
      color: #55585c;
      font-size: 14px;
      line-height: 20px;

      .authInfoLine2,
      .authInfoLine3 {
        position: relative;
        padding-left: 20px;
        margin-top: 8px;
      }

      .authInfoLine3 {
        margin-left: 20px;
      }

      .lineNum {
        position: absolute;
        left: 0;
        top: 0;
        height: 20px;
        line-height: 20px;
      }

      .showMore {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #1966ff;
        width: 48px;
        cursor: pointer;
        margin-top: 4px;
      }

      .smallImgContainer {
        margin-right: 20px;
        margin-top: 20px;
        height: 130px;
        display: inline-block;

        .smallImg {
          height: 100%;

        }
      }
    }
  }

  tr,
  td,
  th {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #12141a;
  }
}
