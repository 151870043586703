$menuHoverColor: #1966ff;

.menuHoverStyle {
  color: $menuHoverColor;
  font-weight: 500;
}

.wrap {
  display: flex;
  padding: 24px 32px !important;
}

.menu {
  position: relative;
  min-width: 95px;
  border-right: 1px solid #eeeff0;

  &-item {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;

    a {
      color: inherit;
    }

    &-not-active {
      color: #565960;
    }

    &:hover {
      @extend .menuHoverStyle;
      cursor: pointer;
    }

    &-active {
      @extend .menuHoverStyle;
      position: relative;

      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 4px;
        height: 20px;
        left: -12px;
        background-color: $menuHoverColor;
        border-radius: 4px;
      }
    }
  }
}

.main {
  padding: 8px 0 8px 32px;
  min-height: calc(100vh - 300px);

  &-header {
    display: flex;
    align-items: baseline;

    .category {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }

    .total {
      font-size: 12px;
      line-height: 16px;
      color: #898b8f;
      margin-left: 12px;
    }
  }

  .content {
    list-style: none;
  }

  .content-item {
    margin-top: 24px;

    &-ques,
    &-ques-single {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #252931;
    }

    &-ques:hover {
      color: $menuHoverColor;
      cursor: pointer;
    }

    &-ans {
      margin-top: 12px;
    }
  }

  .routeLink {
    font-size: 12px;
    line-height: 16px;
    color: #898b8f;
    margin: 0 8px 24px;
    display: inline-block;
  }

  .cateLink {
    margin-left: 0;

    a {
      color: inherit;
    }

    &:hover {
      cursor: pointer;
      color: $menuHoverColor;
    }
  }
}

:global {
  .k-render__content * {
    // font-size: 14px !important;
    // white-space: pre-wrap;
    // line-height: 26px;
    // padding: 0 !important;
  }

  .k-render__content *:not(a) {
    color: #565960 !important;
  }

  .k-render__content .innerdocbody.adit-container .heading-h2 span,
  .k-render__content .adit-outer-container .adit-container .heading-h2 span,
  .k-render__content .innerdocbody.adit-container .heading-h3 span,
  .k-render__content .adit-outer-container .adit-container .heading-h3 span {
    // font-weight: normal !important;
    // font-size: 14px !important;
  }
}
