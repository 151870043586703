.tip {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;

  .tipLink {
    background-color: transparent;
    color: #1966ff;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;
  }

  .arrow {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }
}

@media (max-width: 1636px) {
  .tip {
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-bottom: 15px;
  
    .tipLink {
      background-color: transparent;
      color: #1966ff;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      transition: color 0.3s;
    }
  
    .arrow {
      width: 10px;
      height: 10px;
      position: relative;
      top: -1px;
    }
  }
}
