.faq {
  padding: 24px 51px 0 24px;
  box-sizing: border-box;

  &-item {
    margin-bottom: 28px;

    &-question {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      & img {
        margin-right: 8px;
        width: 32px;
        height: 31px;
      }

      .title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }
    }

    &-answer {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #565960;
      margin-left: 42px;
    }
  }

  &-more {
    margin-top: 20px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #898b8f;
  }
}

@media (max-width: 1636px) {
  .faq {
    padding: 24px 51px 0 24px;
    box-sizing: border-box;

    &-item {
      margin-bottom: 18px;

      &-question {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #252931;
        margin-bottom: unset;
        
        & img {
          margin-right: 8px;
          width: 22.67px;
          height: 20.67px;
        }

        .title {
          font-size: 16px;
          line-height: 16px;
          color: #000000;
        }
      }

      &-answer {
        font-size: 13px;
        line-height: 20px;
        margin-left: 35px;
        color: rgba(0, 0, 0, 0.65);
        margin-top: 10px;
      }
    }

    &-more {
      margin-top: 20px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #898b8f;
    }
  }
}
