.displayHide {
  display: none;
}

.opacityHide {
  opacity: 0;
}

.visibilityHide {
  visibility: hidden;
}