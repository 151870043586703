@import "~statics/styles/mixins.scss";

.wrap {
  position: relative;
  width: 100%;
  padding-top: 53px;
  padding-bottom: 68px;
}

.list {
  display: flex;
  justify-content: center;
}

.tips {
  width: 520px;
  height: 42px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #898B8F;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url('./assets/tips.svg') no-repeat center;
    background-size: 14px;
    margin-right: 9px;
  }
}

.settleButton {
  background-color: unset;
  padding: 0;
  padding-top: 34px;
}

@media (max-width: 1636px) {
  .wrap {
    position: relative;
    padding: 26px 44px 30px 44px;
  }

  .tips {
    width: 326px;
    height: 24px;
    font-size: 12px;
    line-height: 16px;
    bottom: 24px;
  }
}