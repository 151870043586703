.bgColorWrapper {
  background: linear-gradient(to right, #d5e4f7 50%, #e3eafa 50%);

  .wrapper {
    margin-top: -60px;
    height: 800px;
    background-size: 1920px 800px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper > div {
    width: 376px;
    transform: translateX(-350px);
    > h1 {
      font-weight: 500;
      font-size: 38px;
      line-height: 54px;
      color: #252931;
    }
    > div:nth-child(2) {
      font-size: 22px;
      line-height: 36px;
      margin: 6px 0 40px;
      color: #898b8f;
    }
  }

  .wrapper :global(.auxo-btn) {
    border-radius: 36px;
    height: 36px;
    padding: 8px 23px;
  }

  .wrapper :global(.auxo-btn-dashed) {
    background-color: transparent !important;
    margin-left: 20px;
  }
}
