@import "~statics/styles/mixins.scss";

.wrapper {
  display: flex;
  align-items: center;
  width: 1181px;
  height: 132px;
  padding: 8px 0px;
  box-sizing: border-box;

  .img {
    width: 224px;
    height: 116px;
    border-radius: 6px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .content {
    margin-left: 16px;
    height: 100%;

    .status {
      padding: 1px 8px 1px 10px;
      gap: 4px;
      height: 22px;
      background: rgba(25, 102, 255, 0.08);
      border-radius: 20px;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1966ff;
      margin-top: 8px;
      overflow: hidden;

      .play-icon {
        width: 12px;
        height: 13px;
        margin-right: 4px;
      }

      >span {
        margin-right: 4px;
        letter-spacing: 1px;
      }

      &-box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #12141a;
      margin-top: 8px;
      cursor: pointer;
    }

    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #55585c;
      opacity: 0.67;
      margin-top: 8px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    .btn-wrp {
      width: 120px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn {
        width: 120px;
        height: 36px;
        border: 1px solid #1966ff;
        border-radius: 100px;
        font-weight: 500;
        font-size: 14px;
        color: #1966ff;

        &:hover,
        &:active {
          background: #1966ff;
          color: #fff;
        }
      }

      .info {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #262626;
        opacity: 0.5;
        margin-top: 8px;
      }
    }
  }
}

@media (max-width: 1636px) {
  .wrapper {
    display: flex;
    align-items: center;
    width: 768px;
    // height: 132px;
    height: 90px;
    padding: 4px 0px;
    box-sizing: border-box;

    .img {
      width: 145px;
      height: 78px;
      border-radius: 6px;
      cursor: pointer;
    }

    .content {
      @include FlexCenter();
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 16px;
      height: 100%;

      .status {
        @include FlexCenter();
        justify-content: flex-start;
        padding: 1px 8px;
        gap: 4px;
        height: 15px;
        background: rgba(25, 102, 255, 0.08);
        border-radius: 20px;

        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #1966ff;
        margin-top: 8px;
        overflow: hidden;

        .play-icon {
          width: 12px;
          height: 13px;
        }

        &-box {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #12141a;
        margin-top: 5px;
        cursor: pointer;
      }

      .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #55585c;
        opacity: 0.67;
        margin-top: 5px;
      }
    }

    .right {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;

      .btn-wrp {
        width: 120px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .btn {
          width: 80px;
          height: 24px;
          border: 1px solid #1966ff;
          border-radius: 100px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #1966ff;
          padding: 0;

          &:hover,
          &:active {
            background: #1966ff;
            color: #fff;
          }
        }

        .info {
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #262626;
          opacity: 0.5;
          margin-top: 8px;
        }
      }
    }
  }
}
