@mixin Card {
  display: flex;
  align-items: center;
  position: relative;
  top: 24px;

  &-animate {
    animation-play-state: running;
  }

  &-animate-paused {
    animation-play-state: paused;
  }

  &-item {
    flex-shrink: 0;
    width: 340px;
    height: 426px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 6px 32px rgba(37, 41, 49, 0.06);
    text-align: center;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;

    &-douyinBusinessCaseTag {
      position: absolute;
      right: 0;
      top: 0;
      width: 98px;
      height: 28px;
      background: linear-gradient(86.82deg, #FF4050 2.63%, rgba(255, 64, 80, 0.4) 100%);
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      color: #FFFFFF;
      line-height: 28px;
    }

    &-wrap {
      height: 426px;
      margin: 0 8px;

      &:hover {
        .card-item {
          width: 420px;
          height: 427px;
          transform: translateY(-65px);
        }

        .card-item-text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }

    &-bg {
      height: 124px;
      width: 100%;
      overflow: hidden;
      transition: all 0.3s;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &-logo {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      box-shadow: 0px 3.2px 12.8px rgba(37, 41, 49, 0.08);
      position: absolute;
      top: 92px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s;
    }

    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #252931;
      margin-top: 54px;
    }

    &-tag {
      margin-top: 10px;
      display: flex;
      justify-content: center;

      &-item {
        color: #1966ff;
        font-size: 12px;
        line-height: 16px;
        padding: 2px 4px;
        background-color: #ebf3ff;
        border-radius: 4px;
        margin: 0 4px;
      }
    }

    &-text {
      font-size: 14px;
      line-height: 20px;
      margin: 16px;
      margin-top: 26px;

      max-height: 80px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: #7f7f7f;
      transition: all 0.3s;
      letter-spacing: 0.01em;
    }

    &-data {
      display: flex;
      align-items: flex-start;
      margin-top: 25px;

      &-item {
        width: 100px;
        padding: 0 12px;
        flex: 1;
        position: relative;

        &::after {
          display: block;
          content: "";
          height: 32px;
          width: 1px;
          background-color: #eeeff0;
          position: absolute;
          right: 0;
          top: 2px;
        }

        &:last-of-type {
          // border: none;
          &::after {
            display: none;
          }
        }

        &-num {
          color: #212533;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          position: relative;
          display: inline-block;
        }

        &-more {
          position: absolute;
          top: -8px;
          right: -12px;
        }

        &-text {
          margin-top: 4px;
          font-size: 12px;
          line-height: 12px;
          color: #bcbdc0;
        }
      }
    }
  }
}

.bg {
  height: 750px;
  padding-top: 60px;
  text-align: center;
  background: #f0f6ff;

  .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #000000;
  }

  .subtitle {
    margin-top: 16px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }

  .container {
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;
    padding-top: 80px;
  }

  .card {
    @include Card;
  }
}

@media (max-width: 1636px) {
  .bg {
    height: 540px;
    padding-top: 53px;
    text-align: center;
    background: #f0f6ff;

    .title {
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      text-align: center;
      color: #000000;
    }

    .subtitle {
      margin-top: 10px;
      font-size: 16px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
    }

    .container {
      overflow-x: hidden;
      height: 100%;
      box-sizing: border-box;
      padding-top: 45px;
    }

    .card {
      display: flex;
      align-items: center;
      position: relative;
      top: 24px;

      &-animate {
        animation-play-state: running;
      }

      &-animate-paused {
        animation-play-state: paused;
      }

      &-item {
        flex-shrink: 0;
        width: 255px;
        height: 323px;
        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0px 6px 32px rgba(37, 41, 49, 0.06);
        text-align: center;
        position: relative;
        transition: all 0.3s;
        overflow: hidden;

        &-wrap {
          height: 380px;
          margin: 0 8px;

          &:hover {
            .card-item {
              width: 280px;
              height: 338px;
              transform: translateY(-40px);
            }

            .card-item-text {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
          }
        }

        &-bg {
          width: 100%;
          height: 82px;
          overflow: hidden;
          transition: all 0.3s;

          > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        &-logo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          box-shadow: 0px 3.2px 12.8px rgba(37, 41, 49, 0.08);
          position: absolute;
          top: 59px;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.3s;
        }

        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 15px;
          color: #252931;
          margin-top: 38px;
        }

        &-tag {
          margin-top: 8px;
          display: flex;
          justify-content: center;

          &-item {
            color: #1966ff;
            font-size: 12px;
            line-height: 13px;
            padding: 2px 4px;
            background-color: #ebf3ff;
            border-radius: 4px;
            margin: 0 4px;
          }
        }

        &-text {
          font-size: 12px;
          line-height: 16px;
          margin: 14px;
          margin-top: 16px;
          word-break: break-all;
          // max-height: 48px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #7f7f7f;
          transition: all 0.3s;
          letter-spacing: 0.01em;
        }

        &-data {
          display: flex;
          align-items: flex-start;
          margin-top: 14px;
          // height: 90px;

          &-item {
            width: 100px;
            padding: 0 8px;
            flex: 1;
            position: relative;

            &::after {
              display: block;
              content: "";
              height: 32px;
              width: 1px;
              background-color: #eeeff0;
              position: absolute;
              right: 0;
              top: 2px;
            }

            &:last-of-type {
              // border: none;
              &::after {
                display: none;
              }
            }

            &-num {
              color: #212533;
              font-weight: 600;
              font-size: 16px;
              line-height: 11px;
              position: relative;
              display: inline-block;
            }

            &-more {
              position: absolute;
              top: -8px;
              right: -12px;
              font-weight: 600;
              font-size: 16px;
              line-height: 11px;
            }

            &-text {
              margin-top: 4px;
              font-size: 12px;
              line-height: 16px;
              color: #bcbdc0;
            }
          }
        }
      }
    }
  }
}
