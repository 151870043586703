.footer {
  width: 100%;
  background-color: #1a2034;
  color: #85878c;
  display: flex;
  justify-content: center;
  padding: 39px 0;
  min-height: 200px;

  &-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1160px;
    box-sizing: unset;
  }

  &-left {
    display: flex;
  }

  &-logo {
    margin-right: 23px;
    width: 36px;
    height: 52px;
    width: 200px;
    height: 76px;
  }

  &-info {
    font-size: 12px;
    line-height: 17px;
    box-sizing: unset;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      white-space: nowrap;

      &-label {
        font-weight: 500;
        margin-right: 16px;
      }

      &-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }

      &-img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      &-link {
        color: #85878c;
        margin-right: 12px;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  &-qrcode {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-width: 80px;
    margin-left: 58px;

    &-img {
      width: 80px;
      height: 80px;
    }

    &-text {
      font-size: 12px;
      position: absolute;
      top: calc(100% + 8px);
      white-space: nowrap;
      line-height: 10px;
    }
  }
}

@import "~statics/styles/mixins.scss";

.footer-new {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 220px;

  background: #252931;
  // min-height: 400px;

  .logo {
    margin-top: 20px;
    width: 200px;
    height: 76px;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 60px;
    flex-wrap: wrap;

    .label {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      opacity: 0.8;
      // margin-right: 40px;
      display: inline-block;
      flex-shrink: 0;
      width: 104px;
    }

    .values {
      display: flex;
      flex-wrap: wrap;

      &-center {
        display: flex;
        align-items: center;

        margin-right: 40px;
        margin-bottom: 24px;
        // justify-content: flex-start;
      }

      &-icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .value {
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }

    }
  }

  .right {
    > h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;

      opacity: 0.8;

      margin-left: 14px;
    }

    > p {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      opacity: 0.4;
    }

    .phone {
      width: 250px;
      margin-top: 8px;
      margin-left: 14px;
      color: var(--fill-fade-0, #FFF);

      font-size: 16px;
      font-weight: 400;
      line-height: 22px; /* 137.5% */

      opacity: 0.4;
    }

    .qrcode {
      @include FlexCenter();
      width: 88px;
      height: 88px;
      background-color: #85878c;
      margin-top: 20px;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
      border-radius: 4px;

      &-text {
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        opacity: 0.4;
        margin-top: 13px;
      }

      &-item {
        @include FlexCenter();
        flex-direction: column;
        width: 120px;
      }

      &-box {
        display: grid;
        grid-template-columns: repeat(2, 130px);
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 1636px) {
  .footer-new {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 26px 46px;

    background: #252931;

    .logo {
      margin-top: 20px;
      width: 133px;
      height: 50px;
    }

    .content {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: 60px;
      flex-wrap: wrap;

      .label {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
        opacity: 0.8;
        // margin-right: 40px;
        display: inline-block;
        flex-shrink: 0;
        width: 104px;
      }

      .values {
        display: flex;
        flex-wrap: wrap;

        &-center {
          display: flex;
          align-items: center;
  
          margin-right: 40px;
          margin-bottom: 24px;
        }

        &-icon {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }

        .value {
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .right {
      // width: 245px;

      > h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;

        opacity: 0.8;

        margin-left: 0;
      }

      > p {
        margin-top: 8px;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;

        opacity: 0.4;
      }

      .qrcode {
        @include FlexCenter();
        width: 58px;
        height: 58px;
        background-color: #85878c;
        margin-top: 12px;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
        border-radius: 4px;

        &-text {
          display: inline-block;
          font-size: 12px;
          line-height: 20px;
          color: #ffffff;
          opacity: 0.4;
          margin-top: 13px;
        }

        &-item {
          @include FlexCenter();
          flex-direction: column;
          width: 60px;
        }

        &-box {
          grid-template-columns: repeat(2, 80px);
        }
      }
    }
  }
}
