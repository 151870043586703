@import "~statics/styles/mixins.scss";

.wrap {
  width: 100%; 
  display: flex;
  justify-content: center;
  background: #fff;
  height: 109px;
}

.content {
  width: 1480px;
  position: relative; 
  top: -114px;
  height: 222px;
  background: rgba(255, 255, 255, 0.56);
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(19.5px);
  border-radius: 8px;
  display: flex;
  padding: 0 96px 22px 47px;
}

.downloadQrcode {
  border-radius: 8px;
  width: 432px;
  height: 176px;
  margin-top: 28px;
  object-fit: cover;
}

.descBox {
  flex: 1;
  margin-top: 36px;
  margin-left: 67px;

  .title {
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
  }
  
  .contentImg {
    margin-top: 18px;
    width: 874px;
  }
}

@media (max-width: 1636px) {
  .wrap {
    height: 120px;
  }

  .content {
    top: -50px;
    width: 987px;
    height: 170px;
    padding: 0 64px 14px 31px;
  }

  .downloadQrcode {
    width: 288px;
    height: 136px;
    margin-top: 18px;
  }

  .descBox {
    margin-top: 23px;
  
    .title {
      font-size: 26px;
      line-height: 33px;
    }
    
    .contentImg {
      margin-top: 18px;
      width: 555px;
    }
  }
}