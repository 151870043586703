@import '../../statics/styles/mixins.scss';

html {
  scroll-behavior: smooth;
}
.header{
  position: sticky;
  top: 0;
  z-index: 500;
  width: 100%;
  height: 54px;
}
.jsls{
  margin: 0 auto;
  width: 1480px;
  height: 54px;
  background: url(https://p6-open-static.ecombdimg.com/tos-cn-i-o8j9kkde8i/66c37a6e09924d00aa7e3b6ee16dab62~tplv-o8j9kkde8i-1.image) no-repeat left center;
  background-size: 204px 33px;
}
.block1wrap {
  position: relative;
  // height: 662px;
  height: 476px;
}

.block1 {
  position: absolute;
  width: 100%;
  // top: -60px;
  top: -88px;
  overflow-y: visible;
}

.home-placeholder {
  @extend .block1;
  position: relative;
  
  &-one {
    height: 620px;
    background-color: #d4e1f7;
    position: relative;
    top: -60px;
  }

  &-two {
    height: 487px;
    width: 100%;
    background: linear-gradient(0deg, #F2F6FE 0%, rgba(242, 246, 254, 0) 60.32%);
  }
}

.content {
  position: relative;
  background: #f0f6ff;
}

.hide {
  display: none;
}

.settle-btn {
  padding-bottom: 16px;
  box-sizing: unset;
}

@media (max-width: 1636px) {
  .header{
    position: sticky;
    top: 0;
    z-index: 500;
    width: 100%;
    height: 54px;
  }
  .jsls{
    margin: 0 auto;
    width: 986px;
    height: 54px;
    background: url(https://p6-open-static.ecombdimg.com/tos-cn-i-o8j9kkde8i/66c37a6e09924d00aa7e3b6ee16dab62~tplv-o8j9kkde8i-1.image) no-repeat left center;
    background-size: 204px 33px;
  }
  .block1wrap {
    position: relative;
    height: 494px;
  }
  
  .block1 {
    position: absolute;
    width: 100%;
    top: -56px;
    overflow-y: visible;
  }
  
  .home-placeholder {
    @extend .block1;
    position: relative;
    
    &-one {
      height: 620px;
      background-color: #d4e1f7;
      position: relative;
      top: -60px;
    }
  
    &-two {
      height: 487px;
      width: 100%;
      background: linear-gradient(0deg, #F2F6FE 0%, rgba(242, 246, 254, 0) 60.32%);
    }
  }
}


.retailStep {
  padding-top: 0;
}
.enterFlowRetailClassName {
  margin-top: 52px;
  padding-top: 0;

  &::after {
    display: none;
  }
  
}

/**  样式写得太分散了，不好改， 在这里hack 一下 **/

@media (max-width: 1636px) {
  .enterFlowRetailClassName {
    margin-top: 0;
    
  }
} 
