@import "~statics/styles/mixins.scss";

.wrapper {
  width: 348px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  overflow: hidden;

  box-sizing: border-box;
  padding: 24px 30px 24px 24px;
  margin-right: 32px;
  min-height: 200px;

  &:last-child {
    margin: 0
  }

  &:first-child {
    margin-left: 0;
  }

  .logo {
    @include FlexCenter();
    justify-content: flex-start;
    width: 100%;

    > img {
      width: 64px;
      height: 64px;
    }
  }

  .title {
    margin-top: 22px;
    display: block;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #000000;
  }

  .desc {
    font-size: 20px;
    line-height: 28px;
    color: #7f7f7f;
    margin-top: 16px;
    // margin-bottom: 46px;
  }
}

@media (max-width: 1636px) {
.wrapper {
  width: 232px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  overflow: hidden;

  box-sizing: border-box;
  padding: 16px;
  margin-right: 32px;

  &:last-child {
    margin: 0
  }

  &:first-child {
    margin-left: 0;
  }

  .logo {
    @include FlexCenter();
    justify-content: flex-start;
    width: 100%;

    > img {
      width: 42px;
      height: 42px;
    }
  }

  .title {
    margin-top: 22px;
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .desc {
    font-size: 14px;
    line-height: 20px;
    color: #7f7f7f;
    margin-top: 16px;
    // margin-bottom: 46px;
  }
}

}
