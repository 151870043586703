.form {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 16px;

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #85878a;
    flex-basis: 72px;
  }

  .searchInput {
    flex: 1;
  }
}

.tip {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.choseType {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #0c60ff;

  &:hover {
    color: #4784ff;
  }

  img {
    margin-right: 8px;
  }
}

.valueText {
  box-sizing: border-box;
  padding-right: 52px;

  position: relative;

  top: 2px;

  display: inline-block;
  max-width: calc(100% - 98px);
  height: 22px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #12141a;

  &:hover {
    //color: #4784ff;
  }

  .edit {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 0;

    color: #0c60ff;

    cursor: pointer;

    .editIcon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      background: url('../../img/edit-type.svg') no-repeat;
    }

    &:hover {
      color: #4784ff;

      .editIcon {
        background: url('../../img/edit-type-hover.svg') no-repeat;
      }
    }
  }
}

.searchWrapper {
  z-index: 10;
  position: relative;
  height: 32px;

  .iconClear {
    right: 12px;
    top: 8px;
    cursor: pointer;
  }
}

/* -------------------------------- 搜索结果 -------------------------------- */
.searchResult {
  position: absolute;
  top: 40px;
  left: 1px;
  right: 1px;
  margin-top: 8px;
  max-height: 290px;
  overflow: auto;
  background: #ffffff;
  box-sizing: border-box;

  .item {
    height: 32px;
    box-sizing: border-box;
    padding: 6px 16px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    cursor: pointer;

    &:hover {
      background: #e3eefc;
    }
  }
}

.emptyResult {
  margin: 8px;
  text-align: center;
}

.categoryChoiceWrapper {
  display: flex;

  & > div {
    height: 376px;
    box-sizing: border-box;
    padding: 4px 0;
    flex: 1;
    background: #f3f4f6;
    border-radius: 4px;
    overflow: auto;

    .categoryItem {
      position: relative;
      display: flex;
      align-items: center;

      box-sizing: border-box;
      padding: 8px 12px;

      line-height: 14px;
      height: 32px;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #55585c;

      cursor: pointer;

      &:hover {
        background: #e3eefc;
      }

      img {
        position: absolute;
        right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    .categoryItemActive {
      color: #1966ff;
    }
  }

  .center {
    margin: 0 8px;
  }
}

.searchInputTriggerSelect {
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  width: 100%;
  padding: 7px 36px 7px 12px;
  border: 1px solid #d4d6d9;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid #1966ff;
  }

  .arrow1 {
    position: absolute;
    right: 9px;
    top: 12px;
    width: 11px;
    height: 6px;

    background-image: url('../../img/arrow1.svg');
    background-size: 100% 100%;
  }

  .arrow2 {
    position: absolute;
    right: 9px;
    top: 12px;
    width: 11px;
    height: 6px;

    background-image: url('../../img/arrow2.svg');
    background-size: 100% 100%;
  }

  .placeholder {
    font-weight: normal;
    display: inline-block;
    line-height: 18px;
    color: #aaacaf;
  }
}

.selectBg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.selectWrapper {
  margin-top: 4px;
  position: relative;
  background: #ffffff;
  height: 384px;
  border: 1px solid #edeef0;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 23, 54, 0.05);
  border-radius: 4px;
  z-index: 2;

  input {
    height: 32px;
    width: 100%;
    padding: 5px 36px 7px 8px;
    border: 1px solid #d4d6d9;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;

    &::placeholder {
      display: inline-block;
      line-height: 18px;
      color: #aaacaf;
    }
  }

  img {
    position: absolute;
  }

  .iconSearch {
    //left: 12px;
    //top: 8px;

    right: 17px;
    top: 16px;
  }
}

.selectInputWrapper {
  margin: 8px;
}

.selectItem {
  height: 330px;
  overflow-y: scroll;

  .selectItemActive {
    background: #e3eefc;
  }

  div {
    position: relative;
    display: flex;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 12px;

    line-height: 14px;
    height: 32px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #55585c;

    cursor: pointer;

    &:hover {
      background: #e3eefc;
    }
  }
}

.table {
  width: 100%;
  border-radius: 4px 0;
}

.tableThead {
  width: 100%;

  th {
    height: 24px;
    box-sizing: border-box;
    padding: 16px;
    background: #f9f9fa;
    width: 166px;
    //border-bottom: 1px solid #edeef0;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

.tbodyWrapper {
  top: -1px;
  width: 100%;
  height: 288px;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.tbodyWrapperNone {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 288px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #85878a;
}

.tableBox{
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 364px;
  border: 1px solid #EDEEF0;
  border-radius: 0px 0px 4px 4px;
}


.transparentBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.tbodyLeve2CategoryWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}

.listItem{
  display: block;
  text-align: left;
}