$borderStyle: 1px solid #eeeff0;

.needCashWrapper {
  padding: 32px 0 16px;
  //border-bottom: 1px solid #eeeff0;
  .feeInfo {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 16px;
    background: linear-gradient(186.13deg, rgba(25, 102, 255, 0.08) 4.66%, rgba(25, 102, 255, 0.02) 94.97%);
    height: 140px;
    margin-bottom: 20px;
    border-radius: 8px;
    &:after {
      content: " ";
      background: #8CB8FF;
      width: 1px;
      height: 100px;
      top: 20px;
      left: 50%;
      position: absolute;
      opacity: 0.2;
    }
    .feeItem {
      display: flex;
      padding-left: 50px;
      padding-right: 10px;
      flex: 1;
    }
    .feeContent {
      margin-left: 20px;
    }
    .feeItemTitle {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      color: #252931;
      img {
        margin-left: 8px;
      }
    }
    .feeItemRule {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #898B8F;
      margin-top: 4px;
    }
    .feeItemRulBtn {
      padding: 0;
      margin-left: 4px;
      min-width: auto;
    }
    .feeItemValue {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #252931;
      display: flex;
      align-items: center;
      &.feeItemValueZero {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: right;
        color: #898B8F;
        .value, .unit {
          text-decoration: line-through;
        }
      }
      .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #898B8F;
        margin-right: 8px;
      }
      .unit {
        font-size: 16px;
      }
      .zero {
        font-family: PingFang SC;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        color: #FF5C00;
        margin-right: 12px;
        text-decoration: none;
      }
      //.unit {
      //  font-size: 12px;
      //}
    }
  }
}

.feeTableRow {
  background: #fff;
  &:hover {
    background: #fff;
    td {
      background: #fff !important;
    }
  }
  td {
    vertical-align: top;
  }
}
.feeTableNameWrapper {
  position: relative;
  flex-flow: column nowrap;
  color: #252931;
  .feeTableName {
    display: inline-block;
    align-items: center;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #252931;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 700px;
    padding-right: 32px;
  }
  .expandBtn {
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
    color: #1966FF;
    right: 0;
    top: 0;
  }
  .feeTableList {
    display: none;
    padding: 12px 12px 0 12px;
    margin-top: 16px;
    background: #F8F9FA;
    border-radius: 8px;
    &.expand {
      display: block;
    }
  }
  .feeTableListItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    .itemRight {
      display: flex;
      align-items: center;
    }
    .itemAmount {
      &.itemAmountZero {
        color: #898B8F;
        text-decoration: line-through;
      }
    }
  }
}

.title {
  font-size: 20px;
  line-height: 28px;
}

.title2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #85878A;
  margin: 21px 0 10px;
}

.needCashItemWrapper {
  padding: 16px 24px;
  border: $borderStyle;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    align-items: center;
  }

  .imgWrapper {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-right: 18px;
  }
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.needCashItem {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    font-size: 14px;
    line-height: 20px;
    color: #12141a;
    display: flex;
    align-items: center;

    &-text {
      margin-right: 3px;
    }
  }
}

.tip {
  font-size: 12px;
  color: #ccc;
}

.popover-content-p {
  width: 200px;

  &-link {
    font-weight: 400;
  }
}

.techCashValue {
  font-weight: 500;
  color: #12141a;
  display: flex;
  align-items: baseline;

  &-text {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
  }
}


.zeroTechCash {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #FF9226;

  &-tips {
    margin-top: 3px;

    span:nth-child(2){
      @extend .text;
    }

    a {
      color: #1966FF;
      margin-left: 3px;
    }
  }
}

.zeroText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FF8413;
}

.zeroSettleTag {
  margin-right: 8px;
}

.techCashListItemRightBox {
  display: flex;
  align-items: center;
}

.box {
  margin-top: 16px;
}

.item {
  &:last-child {
    border-bottom: 0px;
    padding-bottom: 8px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #edeef0;

  .title {
    font-size: 14px;
    line-height: 20px;
    color: #55585c;
  }
}

.drawer {
  position: relative;
  padding-bottom: 55px;
  transition: all .3s;

  &-clear-hidden {
    padding: 0;
  }

  &-convergence {
    max-height: 330px;
    overflow: hidden;
  }

  &-expansion {
    max-height: unset;
    overflow: unset;
  }

  &-btn {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 55px;
    color: #0c60ff;
    background: #FFFFFF;
    left: 0;
    bottom: 0;
    cursor: pointer;

    &:after {
      content: '';
      display: flex;
      width: 22px;
      height: 12px;
      background: url(../../../../../statics/img/header/arrow-up.png) no-repeat center;
      background-size: 12px 7px;
      transform: rotate(180deg);
    }

    &-expansion {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

